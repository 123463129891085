export const isObjectEmpty = (obj) => {

    return Object.keys(obj).length === 0;

};

export const isArrayEmpty = (arr) => {

    if (!Array.isArray(arr))
        return true;

    return arr.length === 0;
};