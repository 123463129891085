export const PRODUCT_TYPE = {
    MACRO_PRODUCT: 'MACRO_PRODUCT',
    SIMPLE_PRODUCT: 'SIMPLE_PRODUCT',
    MICRO_PRODUCT: 'MICRO_PRODUCT',
    INSTALLATION_SERVICE: 'INSTALLATION_SERVICE',
    CONSULTATION_PRODUCT: 'CONSULTATION_PRODUCT'
};

export function parseProductType(product) {

    switch (product.__typename.toLowerCase()) {

        case 'macroproduct':
            return PRODUCT_TYPE.MACRO_PRODUCT;
        case 'simpleproduct':
            return PRODUCT_TYPE.SIMPLE_PRODUCT;
        case 'microproduct':
            return PRODUCT_TYPE.MICRO_PRODUCT;
        case 'consultationproduct':
            return PRODUCT_TYPE.CONSULTATION_PRODUCT;

    }

}