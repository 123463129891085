import DashboardSidebar from './DashboardSidebar';
import DashboardHeader from './DashboardHeader';
import {useContext, useEffect} from 'react';
import {UserContext} from '../../utils/context/appContext';
import Router from 'next/router';

export const DashboardLayout = ({children}) => {

  const [user, setUser] = useContext(UserContext);

  useEffect(() => {

    const userIsAllowedToEnter = !!user.data &&
        (user.data.role.type.toLowerCase().includes('admin') ||
            user.data.role.type.toLowerCase().includes('partner'));

    if (!userIsAllowedToEnter) {

      console.error('+++ User is not allowed to enter. Initiate LOGOUT ++++',
          user);
      const logout = async () => {
        await Router.replace('/');
      };

      logout().then(r => null);

    }

  }, []);

  return <div className="flex h-screen overflow-hidden">

    {/* Sidebar */}
    <DashboardSidebar/>

    {/* Content area */}
    <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

      {/*  Site header */}
      <DashboardHeader/>

      <main>
        {children}
      </main>

    </div>
  </div>;

};