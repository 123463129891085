import React from 'react';
import theme from '../../theme';
import CoreButton from './coreButton';

export default function TextButton({
                                     ref,
                                     onClick,
                                     isLoading,
                                     disabled,
                                     preventDefault,
                                     className,
                                     icon,
                                     size = 'sm',
                                     children,
                                   }) {

  const {
    primaryColor,
    primaryHoverColor,
    secondaryHoverColor,
    secondaryColor,
    textColor,
    textColorDark,
  } = theme;

  return <CoreButton
      ref={!!ref ? ref : null}
      preventDefault={preventDefault}
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      className={`
          transition duration-500 ease-in-out
          rounded-full
          filter
          text-${size}
          px-5
          py-2 
          ${disabled ? 'text-gray-500' : `text-${textColorDark} `}
          ${className}
          `}>
    {children}
  </CoreButton>;

}
