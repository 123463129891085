import {getMarketingCookieAllowed} from './cookieUtils';
import {calculateGrossPrice} from './productUtils';
import * as Sentry from '@sentry/nextjs';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const environment = process.env.NODE_ENV;

export const pageview = () => {

    if (environment === 'development')
        return;

    try {
        window.fbq('track', 'PageView');
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }
};

export const fpAcceptCookies = () => {

    if (environment === 'development')
        return;

    try {
        window.fbq('trackCustom', 'CookiesAccepted');
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }
};

export const addToCart = (lineItem) => {

    if (environment === 'development')
        return;

    try {
        // if (getMarketingCookieAllowed()) {

        // Micro product
        const {__typename, id, title} = lineItem;
        const {netPrice} = lineItem.price;
        const {taxPercent} = lineItem.price.tax;

        const grossPrice = calculateGrossPrice(netPrice, taxPercent);

        window.fbq('track', 'AddToCart', {
            content_name: title,
            content_category: 'Tesla',
            content_ids: [id],
            content_type: 'product',
            value: grossPrice,
            currency: 'EUR',
        });

        // }
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }

};

export const initiateCheckout = () => {

    if (environment === 'development')
        return;

    try {
        // if (getMarketingCookieAllowed()) {
        window.fbq('track', 'InitiateCheckout');
        // }
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }
};

export const trackFbPurchase = (order) => {

    // if (environment === 'development')
    //     return;

    try {
        // if (getMarketingCookieAllowed()) {

        const {
            created_at,
            locale,
            lineItems,
            // priceOverview,
            numbers,
            shippingAddress,
            invoiceAddress,
            paymentMethod,
            shippingType,
            email,
            telephoneNumber
        } = order;

        const {foreName, lastName, country, city,postalCode} = invoiceAddress;


        const {
            orderTotal,
            currencyCode,
        } = numbers;

        const content_ids = lineItems.map(item => {

            return item.data.sku;
        });

        window.fbq('track', 'Purchase',
            {
                value: parseFloat(orderTotal), currency: currencyCode, content_ids,
                fn: foreName,
                ln: lastName,
                em: email,
                ph: telephoneNumber,
                country: country.code,
                ct: city,
                zp: postalCode
            });

        return true;
        // }
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }
};
export const trackContactLead = () => {

    if (environment === 'development')
        return;

    try {
        // if (getMarketingCookieAllowed()) {

        window.fbq('track', 'Contact',
            {});

        return true;
        // }
    } catch (e) {
        Sentry.captureException(new Error(e));
        // Silent
    }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {

    if (environment === 'development')
        return;

    window.fbq('track', name, options);
};
