export const getCountryFromLanguage = (locale) => {

  switch (locale.toLowerCase()) {

    case 'en':
      return 'GB';

    default:
      return locale.toUpperCase();

  }

};

export const generateAlternativeUrlsFromLocalizations = (
    localizations, router) => {

  const {asPath, locale: currentLocale} = router;

  const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

  const alternativeUrlList = localizations.map(({slug, locale, pathList}) => {

    const [_locale] = locale.split('-');
    const pathJoined = !!pathList ? `/${pathList.join('/')}` : '';
    const preUrl = `${SITE_URL}/${_locale}${pathJoined}`;
    const href = `${preUrl}/${slug}`;

    return {
      hrefLang: _locale, href,
    };
  });

  const [_currentLocale] = currentLocale.split('-');

  const currentPath = `${SITE_URL}/${_currentLocale}${asPath}`;
  return [...alternativeUrlList, {hrefLang: _currentLocale, href: currentPath}];

};
