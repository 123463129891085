export const checkIfUserIsAdmin = (user) => {

  if (!user.isAuthenticated || !!!user.data) return false;

  const {type} = user.data.role;
  return type.toLowerCase().includes('admin');

};

export const checkIfUserIsPartner = (user) => {
  const {description} = user.role;

  return description.toLowerCase().includes('admin') ||
      description.toLowerCase().includes('partner');

};