import Layout from '../components/layout/layout';
import {AppProvider} from '../utils/context/appContext';
import {ApolloProvider} from '@apollo/client';
import client from '/apollo-client';
import {appWithTranslation} from 'next-i18next';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from 'react-query'
import '../styles/globals.css';

const queryClient = new QueryClient()

function MyApp({Component, pageProps}) {

    return (
        <>
            <ApolloProvider client={client}>
                <QueryClientProvider client={queryClient}>
                    <AppProvider>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </AppProvider>
                </QueryClientProvider>
            </ApolloProvider>
        </>
    );
}

export default appWithTranslation(MyApp);
