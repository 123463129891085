export const PRODUCT_QUESTION_TYPE = {
  MAIN: 'MAIN',
  BUNDLE: 'BUNDLE'
};

export function parseProductQuestionType(productQuestionType) {

  switch (productQuestionType) {

    case PRODUCT_QUESTION_TYPE.MAIN:
      return PRODUCT_QUESTION_TYPE.MAIN;

    case PRODUCT_QUESTION_TYPE.BUNDLE:
      return PRODUCT_QUESTION_TYPE.BUNDLE;

  }

}