const theme = {
  blueColor: 'blue-700',
  primaryColor: 'blue-700',
  primaryHoverColor: 'blue-900',
  primaryCartBgColor: 'amber-400',
  primaryCartBgHoverColor: 'amber-600',
  secondaryColor: 'coolGray-900',
  secondaryHoverColor: 'coolGray-700',
  textColor: 'white',
  textColorDark: 'gray-800',
  disabledColor: 'gray-400'
};

export default theme;