import {SHIPPING_TYPE} from '../models/enum/shippingType';
import {PRODUCT_TYPE} from '../models/enum/productTypes';
import _ from 'lodash';
import {checkIfObjectHasKeys} from './generalUtils';
import {countries} from 'country-flag-icons';

export const getAddress = (street, postalcode, city) => {
    return `${street}, ${postalcode}, ${city}`;

};

export const getNiceShippingTimeString = (minDay, maxDay) => {

    if (minDay === maxDay) {

    } else {

        return `Versandfertig in ${minDay} bis ${maxDay} Tagen`;

    }

};

export const getShippingAddress = (formValues, formKeys) => {

    let street = null;
    let postalCode = null;
    let city = null;

    if (!formValues[formKeys.CUSTOM_SHIPPING_ADDRESS]) {

        street = formValues[formKeys.INVOICE_ADDRESS_STREET];
        postalCode = formValues[formKeys.INVOICE_ADDRESS_POSTAL_CODE];
        city = formValues[formKeys.INVOICE_ADDRESS_CITY];

    } else {

        street = formValues[formKeys.SHIPPING_ADDRESS_STREET];
        postalCode = formValues[formKeys.SHIPPING_ADDRESS_POSTAL_CODE];
        city = formValues[formKeys.SHIPPING_ADDRESS_CITY];

    }

    return getAddress(street, postalCode, city);

    // }

};

export const findShippingTypeObj = (
    allShippingTypes, selectedShipping, defaultValue) => {

    const countryCode = typeof selectedShipping === 'object' ?
        selectedShipping.code :
        selectedShipping;

    const countryList = _.flattenDeep(allShippingTypes.map(shippingType => {

        const {countries, ...rest} = shippingType;

        return countries.map(country => ({
            ...country, ...rest,
        }));

    }));

    const shippingType = countryList.find(({code}) => code === countryCode);

    if (!!shippingType)
        return shippingType;

    return defaultValue;

};

export const findShippingType = (lineItems) => {

    let shippingType = SHIPPING_TYPE.SHIP_BY_COURIER;

    const hasOrderedInstallation = lineItems.filter(
        lineItem => lineItem.__typename.toLowerCase().includes('installation')).length > 0;

    if (hasOrderedInstallation)
        shippingType = SHIPPING_TYPE.INSTALLATION;

    return shippingType;

};

export const calculateSubTotalNetFromLineItems = (lineItems) => {

    let subTotalNet = 0;

    for (let lineItem of lineItems) {

        const component = !!lineItem.__component ? lineItem.__component : lineItem.__typename;

        const isWooOrder = component.includes("woo")
        const isCustomProduct = component.includes("custom")
        const isInstallation = component.includes("installation")

        //TODO Fix this
        const price = (isCustomProduct || isWooOrder || isInstallation) ? lineItem.data.price : lineItem?.price && !!lineItem?.price?.netPrice ? lineItem.price : lineItem.data.price;

        const {qty} = lineItem;

        const _price = price?.isFree ?
            0 :
            price.inSale ? price.salePrice : price.netPrice;

        subTotalNet += isWooOrder ? price.subTotal : (_price * qty);

    }

    return subTotalNet;

};

export const getLineItemComponentFromTypename = (typeName) => {

    if (typeName.toLowerCase().includes('micro'))
        return 'order.micro-product';

    if (typeName.toLowerCase().includes('simple'))
        return 'order.simple-product';

    if (typeName.toLowerCase().includes('installation'))
        return 'order.installation-service';

    if (typeName.toLowerCase().includes('custom'))
        return 'order.custom-product';

};
