import Lottie from 'lottie-react';
import animationData from '../public/lottie/empty-bag.json';

export const EmptyCart = ({
                                    height = 100,
                                    width = 100,
                                  }) => {

  const style = {
    height,
    width
  }

  return <Lottie
      style={style}
      animationData={animationData}
      loop={false}
      autoplay={true}
      rendererSettings={
        {preserveAspectRatio: 'xMidYMid slice'}
      }
  />;

};
