import React, {useContext, useEffect, useRef} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {checkIfUndefined} from '../../lib/utils/generalUtils';
import {
  adminRoutes,
  affiliatePartnerRoutes,
  workshopPartnerRoutes,
} from '../../lib/routes';
import AMPTechLogoSvg from '../svg/amptechLogoSvg';
import {UIContext, UserContext} from '../../utils/context/appContext';
import {checkIfUserIsAdmin} from '../../lib/utils/userUtils';
import {closeSideBar, toggleSideBar} from '../../lib/utils/uiUtils';
import {useTranslation} from 'next-i18next';

function DashboardSidebar({}) {

  const [user] = useContext(UserContext);
  const [ui, setUi] = useContext(UIContext);
  const {t: translateMenu} = useTranslation('menu');

  const sideMenuIsOpen = true;
  const router = useRouter();

  const {pathname} = router;
  const page = checkIfUndefined(pathname.split('/')[2]) ?
      '' :
      pathname.split('/')[2];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  let routes = null;

  if (!!user.data) {
    const {type: roleType} = user.data.role;
    if (roleType.includes('super_admin'))
      routes = adminRoutes(page, translateMenu);
    if (roleType.includes('affiliate_partner'))
      routes = affiliatePartnerRoutes(page, translateMenu);
    if (roleType.includes('partner_workshop'))
      routes = workshopPartnerRoutes(page, translateMenu);
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({target}) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sideMenuIsOpen || sidebar.current.contains(target) ||
          trigger.current.contains(target)) return;
      closeSideBar(ui, setUi);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!sideMenuIsOpen || keyCode !== 27) return;
      closeSideBar(ui, setUi);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
      <div className="w-72">
        {/* Sidebar backdrop (mobile only) */}
        <div
            className={`fixed inset-0 bg-coolGray-900 bg-opacity-30 z-40 hidden z-auto transition-opacity duration-200 ${sideMenuIsOpen ?
                'opacity-100' :
                'opacity-0 pointer-events-none'}`} aria-hidden="true"/>

        {/* Sidebar */}
        <div
            id="sidebar"
            ref={sidebar}
            className={`absolute z-40 left-0 top-0 static left-auto top-auto translate-x-0 transform h-screen overflow-y-scroll overflow-y-auto no-scrollbar w-72 flex-shrink-0 bg-coolGray-900 p-4 transition-transform duration-200 ease-in-out ${sideMenuIsOpen ?
                'translate-x-0' :
                '-translate-x-64'}`}
        >

          {/* Sidebar header */}
          <div className="flex justify-between mb-10 pr-3 sm:px-2">
            {/* Close button */}
            <button
                ref={trigger}
                className="hidden text-coolGray-500 hover:text-coolGray-400"
                onClick={(e) => toggleSideBar(e, ui, setUi)}
                aria-controls="sidebar"
                aria-expanded={sideMenuIsOpen}
            >
              <span className="sr-only">Close sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"/>
              </svg>
            </button>
            {/* Logo */}
            <div className="w-52">
              <AMPTechLogoSvg light useCustomSwooshColor
                              swooshColor={'#445888'}/>
            </div>
          </div>

          {/* Links */}
          <div>
            <h3 className="text-tiny uppercase text-coolGray-400 font-semibold pl-3 tracking-wider"> {translateMenu(
                'PAGES')} </h3>
            <ul className="mt-3 flex flex-col gap-1">

              {!!routes && routes.map((route, index) => {

                const key = `DASHBOARD_ROUTE_${index}`;
                const {
                  svgIcon,
                  color,
                  title,
                  activeRoute,
                  link,
                  isAdminRoute,
                } = route;

                const {
                  text: textColor,
                  textHover: textHoverColor,
                  activeRouteBg: activeRouteBgColor,
                } = color;

                const isAdmin = checkIfUserIsAdmin(user);
                const currentPage = page === activeRoute;

                if ((isAdminRoute && isAdmin) || !isAdminRoute)
                  return <li key={key}
                             className={`flex flex-row w-full items-center px-3 py-2 rounded-sm mb-0.5 last:mb-0 transition  ${currentPage ?
                                 `border-l-2 border-coolGray-50 bg-${activeRouteBgColor} text-coolGray-50` :
                                 'border-l-2 border-coolGray-900 text-coolGray-500 hover:text-coolGray-200 hover:bg-coolGray-700 rounded-md'
                             }`}>
                    <Link href={link}>
                      <a className={`block w-full ${page ===
                      '' && 'hover:text-' + textColor}`}>
                        <div
                            className="flex flex-row gap-5 transition-colors">
                          {svgIcon}
                          <span
                              className={`text-sm `}> {title} </span>
                        </div>
                      </a>
                    </Link>
                  </li>;

              })}


            </ul>
          </div>

        </div>
      </div>
  );
}

export default DashboardSidebar;