export const mainRoutes = (translateMenu, {locale}) => [
  {
    'title': translateMenu('HOME'),
    'uri': '/',
  }, {
    'title': translateMenu('MODEL_S'),
    'uri': `/${locale}/model-s-${locale}`,
  }, {
    'title': translateMenu('MODEL_3'),
    'uri': `/${locale}/model-3-${locale}`,
  }, {
    'title': translateMenu('MODEL_X'),
    'uri': `/${locale}/model-x-${locale}`,
  }, {
    'title': translateMenu('MODEL_Y'),
    'uri': `/${locale}/model-y-${locale}`,
  }, {
    'title': 'JOWUA',
    'uri': `/${locale}/jowua-${locale}`,
  },
];

export const secondaryRoutes = (translateMenu, locale) => [
  {
    'title': translateMenu('FAQ'),
    'uri': `/${locale}/faq`,
  }, {
    'title': translateMenu('CONTACT'),
    'uri': `/${locale}/contact`,
  }, {
    'title': translateMenu('PARTNERS'),
    'uri': `/${locale}/partners`,
  }, {
    'title': translateMenu('SUPPORT'),
    'uri': `/${locale}/support`,
  }, {
    'title': translateMenu('VIN_DECODER'),
    'uri': `/${locale}/vin-decoder`,
  },
];

export const categoryRoutes = (translateMenu, {locale}) => {

  return [
    {
      'title': translateMenu('MODEL_S'),
      'uri': `/${locale}/model-s-${locale}`,
    }, {
      'title': translateMenu('MODEL_3'),
      'uri': `/${locale}/model-3-${locale}`,
    }, {
      'title': translateMenu('MODEL_X'),
      'uri': `/${locale}/model-x-${locale}`,
    }, {
      'title': translateMenu('MODEL_Y'),
      'uri': `/${locale}/model-y-${locale}`,
    },
  ];
};
export const userAccountRoutes = (translateMenu) => {

  return [
    {
      text: translateMenu('ORDERS'),
      key: 'orders',
    },
    {
      text: translateMenu('RETOUR'),
      key: 'retoure',
    },
    {
      text: translateMenu('INVOICES'),
      key: 'invoices',
    },
    {
      text: translateMenu('INSTALLATIONS'),
      key: 'account-installations',
    },
    {
      text: translateMenu('ADDRESS_BOOK'),
      key: 'address',
    },
    {
      text: translateMenu('PRIVATE_DATA'),
      key: 'privateData',
    },
    {
      text: translateMenu('ACCESS_DATA'),
      key: 'accessData',
    },
  ];
};

export const dashboardRoutes = (currentPage, translateMenu) => {

  const textColor = 'coolGray-200';
  const textHoverColor = 'white';
  const activeRouteBg = 'coolGray-900';

  const primaryIconColor = 'coolGray-500';
  const secondaryIconColor = 'coolGray-600';
  const triIconColor = 'coolGray-700';

  return [

    {
      title: translateMenu('DASHBOARD'),
      link: '/dashboard',
      activeRoute: '',
      isAdminRoute: false,
      availableFor: [''],
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
      </svg>,

    },
    {
      title: translateMenu('USERS'),
      link: '/dashboard/users',
      activeRoute: 'users',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
      </svg>,

    },
    {
      title: translateMenu('ORDERS'),
      link: '/dashboard/orders',
      activeRoute: 'orders',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
      </svg>,

    }, {
      title: translateMenu('SUPPLIER_ORDERS'),
      link: '/dashboard/supplier-orders',
      activeRoute: 'supplier-orders',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>,

    },
    {
      title: translateMenu('COMPANY_INVOICES'),
      link: '/dashboard/my-invoices',
      activeRoute: 'my-invoices',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
      </svg>,

    },
    {
      title: translateMenu('CREATE_ORDER'),
      link: '/dashboard/bulk',
      activeRoute: 'bulk',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
      </svg>,

    },
    {
      title: translateMenu('INVOICES'),
      link: '/dashboard/invoices',
      activeRoute: 'invoices',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
      </svg>,

    }, {
      title: translateMenu('CANCELLATIONS'),
      link: '/dashboard/cancellations',
      activeRoute: 'cancellations',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"/>
      </svg>,

    },
    {
      title: translateMenu('ALL_INSTALLATIONS'),
      link: '/dashboard/installations',
      activeRoute: 'installations',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
      </svg>,

    }, {
      title: translateMenu('REFERRALS'),
      link: '/dashboard/referrals',
      activeRoute: 'referrals',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
      </svg>,

    },
    {
      title: translateMenu('LOGISTICS'),
      link: '/dashboard/logistics',
      activeRoute: 'logistics',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"/>
      </svg>,

    },
    {
      title: translateMenu('PRODUCTS'),
      link: '/dashboard/products',
      activeRoute: 'products',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"/>
      </svg>,

    },
    {
      title: translateMenu('STOCK'),
      link: '/dashboard/stock',
      activeRoute: 'stock',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
      </svg>,
    },
    {
      title: translateMenu('MEDIA'),
      link: '/dashboard/media',
      activeRoute: 'media',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>,

    },
    {
      title: translateMenu('GUIDE'),
      link: '/dashboard/guides',
      activeRoute: 'guides',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
      </svg>,

    }, {
      title: translateMenu('SETTINGS'),
      link: '/dashboard/page-settings',
      activeRoute: 'page-settings',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
      </svg>,

    },

  ];
};

export const adminRoutes = (currentPage, translateMenu) => {

  const textColor = 'coolGray-200';
  const textHoverColor = 'white';
  const activeRouteBg = 'coolGray-900';

  const primaryIconColor = 'coolGray-500';
  const secondaryIconColor = 'coolGray-600';
  const triIconColor = 'coolGray-700';

  return [

    {
      title: translateMenu('DASHBOARD'),
      link: '/dashboard',
      activeRoute: '',
      isAdminRoute: false,
      availableFor: [''],
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
      </svg>,

    },
    {
      title: translateMenu('USERS'),
      link: '/dashboard/users',
      activeRoute: 'users',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
      </svg>,

    },
    {
      title: translateMenu('ORDERS'),
      link: '/dashboard/orders',
      activeRoute: 'orders',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
      </svg>,

    }, {
      title: translateMenu('SUPPLIER_ORDERS'),
      link: '/dashboard/supplier-orders',
      activeRoute: 'supplier-orders',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>,

    },
    {
      title: translateMenu('COMPANY_INVOICES'),
      link: '/dashboard/my-invoices',
      activeRoute: 'my-invoices',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
      </svg>,

    },
    {
      title: translateMenu('CREATE_ORDER'),
      link: '/dashboard/bulk',
      activeRoute: 'bulk',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
      </svg>,

    },
    {
      title: translateMenu('INVOICES'),
      link: '/dashboard/invoices',
      activeRoute: 'invoices',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
      </svg>,

    }, {
      title: translateMenu('CANCELLATIONS'),
      link: '/dashboard/cancellations',
      activeRoute: 'cancellations',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"/>
      </svg>,

    },
    {
      title: translateMenu('ALL_INSTALLATIONS'),
      link: '/dashboard/installations',
      activeRoute: 'installations',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
      </svg>,

    }, {
      title: translateMenu('REFERRALS'),
      link: '/dashboard/referrals',
      activeRoute: 'referrals',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
      </svg>,

    },
    {
      title: translateMenu('LOGISTICS'),
      link: '/dashboard/logistics',
      activeRoute: 'logistics',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"/>
      </svg>,

    },
    {
      title: translateMenu('PRODUCTS'),
      link: '/dashboard/products',
      activeRoute: 'products',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"/>
      </svg>,

    },
    {
      title: translateMenu('STOCK'),
      link: '/dashboard/stock',
      activeRoute: 'stock',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
      </svg>,
    },
    {
      title: translateMenu('MEDIA'),
      link: '/dashboard/media',
      activeRoute: 'media',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>,

    },
    {
      title: translateMenu('GUIDE'),
      link: '/dashboard/guides',
      activeRoute: 'guides',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
      </svg>,

    }, {
      title: translateMenu('SETTINGS'),
      link: '/dashboard/page-settings',
      activeRoute: 'page-settings',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
      </svg>,

    },

  ];
};

export const workshopPartnerRoutes = (currentPage, translateMenu) => {

  const textColor = 'coolGray-200';
  const textHoverColor = 'white';
  const activeRouteBg = 'coolGray-900';

  const primaryIconColor = 'coolGray-500';
  const secondaryIconColor = 'coolGray-600';
  const triIconColor = 'coolGray-700';

  return [

    {
      title: translateMenu('DASHBOARD'),
      link: '/dashboard',
      activeRoute: '',
      isAdminRoute: false,
      availableFor: [''],
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
      </svg>,

    },
    {
      title: translateMenu('ORDERS'),
      link: '/dashboard/orders',
      activeRoute: 'orders',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
      </svg>,

    },
    {
      title: translateMenu('COMPANY_INVOICES'),
      link: '/dashboard/my-invoices',
      activeRoute: 'my-invoices',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
      </svg>,

    },
    {
      title: translateMenu('CREATE_ORDER'),
      link: '/dashboard/bulk',
      activeRoute: 'bulk',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
      </svg>,

    },
    {
      title: translateMenu('ALL_INSTALLATIONS'),
      link: '/dashboard/installations',
      activeRoute: 'installations',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
      </svg>,

    }, {
      title: translateMenu('REFERRALS'),
      link: '/dashboard/referrals',
      activeRoute: 'referrals',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
      </svg>,

    },

    {
      title: translateMenu('MEDIA'),
      link: '/dashboard/media',
      activeRoute: 'media',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>,

    },
    {
      title: translateMenu('GUIDE'),
      link: '/dashboard/guides',
      activeRoute: 'guides',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
      </svg>,

    },

  ];
};

export const affiliatePartnerRoutes = (currentPage, translateMenu) => {

  const textColor = 'coolGray-200';
  const textHoverColor = 'white';
  const activeRouteBg = 'coolGray-900';

  const primaryIconColor = 'coolGray-500';
  const secondaryIconColor = 'coolGray-600';
  const triIconColor = 'coolGray-700';

  return [

    {
      title: translateMenu('DASHBOARD'),
      link: '/dashboard',
      activeRoute: '',
      isAdminRoute: false,
      availableFor: [''],
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
      </svg>,

    },
    {
      title: translateMenu('REFERRALS'),
      link: '/dashboard/referrals',
      activeRoute: 'referrals',
      isAdminRoute: false,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
      </svg>,

    },
    {
      title: translateMenu('LOGISTICS'),
      link: '/dashboard/logistics',
      activeRoute: 'logistics',
      isAdminRoute: true,
      color: {
        text: textColor,
        textHover: textHoverColor,
        activeRouteBg: activeRouteBg,
      },
      svgIcon: <svg className="w-6 h-6" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"/>
      </svg>,

    },
  ];
};

export const footerPaths = (translateMenu, locale) => {
  return {
    col2Items: [
      {
        'text': translateMenu('PAYMENT_TYPES'),
        'url': `/${locale}/payment-types`,
        'external': false,
      },
      {
        'text': translateMenu('SHIPPING_TYPES'),
        'url': `/${locale}/shipping-types`,
        'external': false,
      },
      {
        'text': translateMenu('AGB'),
        'url': `/${locale}/agb`,
        'external': false,
      },
      {
        'text': translateMenu('PRIVACY_POLICY'),
        'url': `/${locale}/datenschutzerklaerung`,
        'external': false,
      },
      {
        'text': translateMenu('RETURN_POLICY'),
        'url': `/${locale}/widerrufsbelehrung`,
        'external': false,
      },
      {
        'text': translateMenu('IMPRINT'),
        'url': `/${locale}/impressum`,
        'external': false,
      },
    ],
    col3Items:
        [
          {
            'text': translateMenu('CONTACT'),
            'url': `/${locale}/contact`,
            'external': false,
          },
          {
            'text': translateMenu('FAQ'),
            'url': `/${locale}/faq`,
            'external': false,
          },
          {
            'text': translateMenu('SUPPORT'),
            'url': `/${locale}/support`,
            'external': false,
          },
          {
            'text': 'OBD2',
            'url': `/${locale}/obd2`,
            'external': false,
          },
          {
            'text': translateMenu('MODEL_S_ACCESSORIES'),
            'url': `/${locale}/model-s-${locale}`,
            'external': false,
          },
          {
            'text': translateMenu('MODEL_3_ACCESSORIES'),
            'url': `/${locale}/model-3-${locale}`,
            'external': false,
          },
          {
            'text': translateMenu('MODEL_X_ACCESSORIES'),
            'url': `/${locale}/model-x-${locale}`,
            'external': false,
          },
          {
            'text': translateMenu('MODEL_Y_ACCESSORIES'),
            'url': `/${locale}/model-y-${locale}`,
            'external': false,
          },
          {
            'text': translateMenu('NEWSLETTER'),
            'url': `/${locale}/newsletter`,
            'external': false,
          },
        ],
    col4Items:
        [
          {
            'text': translateMenu('FACEBOOK'),
            'url': 'https://www.facebook.com/amptech.storeX/',
            'external': true,
          },
          {
            'text': translateMenu('INSTAGRAM'),
            'url': 'https://www.instagram.com/amptech.store',
            'external': true,
          },
          {
            'text': translateMenu('YOUTUBE'),
            'url': 'https://www.youtube.com/channel/UCcmt9ZYebDcLS3GUXWLS95Q/featured',
            'external': true,
          },
        ],
  };
};
