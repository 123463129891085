import React from 'react';
import Link from 'next/link';
import {getCountryFromLanguage} from '../../lib/utils/intlUtils';
import Flag from '../flag';

function LanguageMenu({className, router}) {

  const {locale: currentLocale, locales, asPath} = router;

  return (
      <div className={`${className} flex flex-row items-center gap-3 px-2`}>

        {locales.map(locale => {

          const isCurrentLanguage = currentLocale === locale;
          const countryCode = getCountryFromLanguage(locale).toUpperCase();

          return <Link key={`FLAG_${locale}`} href={'/'} locale={locale}>
            <a>
              <Flag
                  className={`h-5 w-5 rounded-full transition-opacity hover:opacity-100 ${isCurrentLanguage ?
                      '' :
                      'opacity-50'}`} locale={locale}/>
              {/*<ReactCountryFlag*/}
              {/*    className={`rounded-xl transition-opacity hover:opacity-100 ${isCurrentLanguage ?*/}
              {/*        '' :*/}
              {/*        'opacity-50'}`}*/}
              {/*    countryCode={countryCode}*/}
              {/*    svg*/}
              {/*    style={{*/}
              {/*      width: '1.3rem',*/}
              {/*      height: '1.3rem',*/}
              {/*    }}/>*/}
            </a>
          </Link>;

        })}


      </div>
  );
}

export default LanguageMenu;
