import {
    calculateTotalPrice,
    removeOrderItem, updateQty,
} from '../../lib/utils/cartUtils';
import {
    calculateShippingInformation,
} from '../../lib/utils/productUtils';
import Image from 'next/image';
import {parseToPriceString} from '../../lib/utils/priceUtils';
import InputForm from '../forms/inputForm';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {PRODUCT_TYPE} from "../../lib/models/enum/productTypes";

const _ = require('lodash');

export default function CartOrderItem({
                                          orderItem,
                                          index,
                                          cart,
                                          setCart,
                                          configurationOptions,
                                          showInteractions = true,
                                          handleOnDeleteCartItem,
                                      }) {

    const {t: translatePages} = useTranslation('pages');
    const {t: translateCommon} = useTranslation('common');

    const onRemoveItem = (index) => {

        if (process.browser) {
            const updatedCart = removeOrderItem(orderItem);
            setCart(updatedCart);

            if (!!handleOnDeleteCartItem)
                handleOnDeleteCartItem(updatedCart);
        }

    };

    const [mainProduct] = orderItem;
    const {
        title,
        shortTitle: productTitle,
        thumbnail,
        __typename,
        qty,
        type
    } = mainProduct;

    const _qty = !!qty ? parseInt(qty) : 1;
    const [itemQty, setItemQty] = useState(_qty);

    useEffect(() => {
        setItemQty(_qty);
    }, [_qty]);

    const isMicroProduct = __typename.toLowerCase().includes('micro');
    const totalPrices = orderItem.map(
        ({price, coupons, qty}) => ({price, coupons, qty}),
    );

    const priceObj = calculateTotalPrice(totalPrices, cart.taxPercent);
    const {
        currency,
        currencyCode,
        inSale,
        isFree,
        totalInSalePrice,
        totalPrice,
    } = priceObj;
    const stockInformationProducts = _.flattenDeep(orderItem.map(lineItem => {

        if (lineItem.stocks) {
            return lineItem.stocks;

        } else {
            return [];
        }

    }));

    const shippingInformation = calculateShippingInformation(
        stockInformationProducts);
    const isConsultationProduct = type === PRODUCT_TYPE.CONSULTATION_PRODUCT;

    const shippingTime = isConsultationProduct? {friendlyText: translateCommon(
            'FORMS.NEWSLETTER_CONFIRM_VIA_BTN_IN_MAIL')} : shippingInformation.shippingTime;

    // const {shippingTime} = shippingInformation;


    const handleOnChangeQty = (e) => {

        const [first] = orderItem;
        const {cartIndex} = first;
        if (!!!process.browser)
            return;

        const {value} = e.target;
        const qty = parseInt(value);

        const updatedCart = updateQty(parseInt(cartIndex), qty);
        setCart(updatedCart);

    };

    return <div
        className={'flex flex-row my-2 items-center pb-6 w-full p-3 justify-between'}>
        <div className={'flex flex-row gap-2 w-full pr-3'}>
            <div className={'flex flex-row flex-grow'}>
                <div
                    className={'mr-2 mb-auto border rounded '}>

                    <div className={'relative w-24 h-24 md:w-32 md:h-32'}>
                        <Image
                            src={thumbnail.url}
                            className={''}
                            alt={thumbnail.alternativeText}
                            layout={'fill'}
                            objectFit={'cover'}
                            quality={100}/>
                    </div>

                </div>

                {/* Normal AMPTech Products */}

                {!isConsultationProduct && <div className={'flex flex-col flex-grow pr-2 gap-2'}>
                    <p className={'text-gray-900 text-sm font-medium'}> {!!productTitle ?
                        productTitle :
                        title} </p>


                    {isMicroProduct && !!configurationOptions &&
                        configurationOptions.length > 0 &&
                        configurationOptions[0].allQuestions.map((question, index) =>
                            question.active && <div key={`QUESTION_${question.id}`}
                                                    className={'text-tiny text-gray-500 flex flex-row justify-between md:max-w-xs'}>
                                <span> {question.shortQuestion}</span>
                                <span
                                    className={'font-medium'}>{configurationOptions[0].allAnswers[index].Answer} </span>
                            </div>)
                    }

                    <div className={''}>

                        <p className={'text-gray-700 text-sm'}>
                            {!isConsultationProduct && <span>{itemQty}x {' '}</span>}
                            <span
                                className={' ml-1 font-medium'}>

             {inSale ?
                 parseToPriceString(_qty * totalInSalePrice) :
                 parseToPriceString(_qty * totalPrice)
             })
            </span>
                        </p>

                    </div>
                    <p className={'text-tiny font-semibold text-gray-700'}>

                        {shippingTime.friendlyText}

                    </p>


                </div>}

                {/* Consultation Products like STARTECH */}

                {isConsultationProduct && <div className={'flex flex-col flex-grow pr-2 gap-2'}>
                    <p className={'text-gray-900 text-sm font-medium'}> {!!productTitle ?
                        productTitle :
                        title} </p>


                    <div className={'flex flex-col gap-3'}>
                        <p className={'text-gray-700 text-sm'}>
                            <span
                                className={'font-medium'}>
                                {translatePages('CATEGORY.FROM_PRICE', {price: parseToPriceString(_qty * totalPrice),})}
                            </span>
                        </p>

                        <p className={'text-tiny w-64'}> {translateCommon('STARTECH_ORDERS')} </p>

                        <div>
                            {!!configurationOptions &&
                                configurationOptions.length > 0 &&
                                configurationOptions[0].allQuestions.map((question, index) =>
                                    <div key={`QUESTION_${question.id}`}
                                         className={'text-tiny text-gray-500 flex flex-row justify-between md:max-w-xs'}>
                                        <span> {question.question}</span>
                                        <span
                                            className={'font-medium'}>{configurationOptions[0].allAnswers[index].title} </span>
                                    </div>)
                            }
                        </div>
                    </div>
                    <p className={'text-tiny font-semibold text-gray-700'}>

                        {shippingTime.friendlyText}

                    </p>


                </div>}
            </div>
        </div>
        <div className={'h-full flex flex-col items-end gap-2 justify-between'}>
            {!!showInteractions && <button
                onClick={() => onRemoveItem(orderItem)}
                className={'transition duration-500 transform hover:scale-150'}>
                <svg
                    className={'h-4 w-4 text-gray-500 hover:text-gray-900 transition transition-all'}
                    fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>}

            {/*{!!showInteractions ?*/}
            {/*    <InputForm className={'w-16'}*/}
            {/*               onChange={handleOnChangeQty}*/}
            {/*               inputKey={`cartItems.qty.${index}`}*/}
            {/*               isRequired={true}*/}
            {/*               useReactForm={false}*/}
            {/*               value={itemQty}*/}
            {/*               type={'number'}*/}
            {/*               placeholder={translateCommon('AMOUNT')}*/}
            {/*               height={'h-12'}*/}
            {/*               showPlaceholderAsLabel*/}
            {/*               useMin*/}
            {/*               useMax*/}
            {/*               min={1}*/}
            {/*               max={10}/> :*/}
            {/*    <span className={'text-sm '}> {itemQty}x </span>}*/}


        </div>
    </div>;

}
