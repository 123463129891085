import React, {useContext, useEffect, useRef, useState} from 'react';
import Script from 'next/script';
import {useRouter} from 'next/router';

import Cookie from 'js-cookie';
import {Toaster} from 'react-hot-toast';
import {useTranslation} from 'next-i18next';

import {MainLayout} from './mainLayout';
import {DashboardLayout} from './dashboardLayout';

import * as fbq from '../../lib/utils/fpixel';
import * as gtag from '../../lib/utils/gtag';
import TailwindUiModal from './TailwindUiModal';
import {gtagCookiesAccepted} from '../../lib/utils/gtag';
import {fpAcceptCookies} from '../../lib/utils/fpixel';
import clientConfig from '../../client.config';

export default function Layout({children, home}) {
  const {t: translateCommon} = useTranslation('common');

  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [showCookieNotActiveModal, setShowCookieNotActiveModal] = useState(
      false);
  let modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  let currentPath = router.pathname;

  const showCustomerLayout = !currentPath.includes('dashboard') ||
      currentPath.includes('login');

  const ampCookie = Cookie.get('amp-cookie-consent');
  const cookieConsentExists = !!ampCookie;

  useEffect(() => {
    if (!!ampCookie) {
      const cookieConsent = JSON.parse(ampCookie);

      if (!!cookieConsent) {

        // This pageview only triggers the first time (it's important for Pixel to have real information)
        fbq.pageview();

        const handleRouteChange = (url) => {
          fbq.pageview();
          gtag.pageview(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange);
        };
      }
    }

  }, [router.events, ampCookie]);

  useEffect(() => {

    if (typeof window !== 'undefined' &&
        !!!navigator.cookieEnabled) {

      setShowCookieNotActiveModal(true);

    } else {

      if (!cookieConsentExists) {

        setShowCookieModal(true);

      }

    }

    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });

    router.events.on('routeChangeComplete', () => {

      setIsLoading(false);

      setIsAutoScrolling(true);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      setTimeout(
          () => {
            setIsAutoScrolling(false);
          },
          500);

    });

  }, []);

  const handleAcceptCookies = (e, value = false) => {

    e.preventDefault();


    const url = `${clientConfig.siteUrl}${currentPath}`;

    if (value) {
      gtagCookiesAccepted(url);
      fpAcceptCookies();
    }

    Cookie.set('amp-cookie-consent', value,
        {expires: new Date(new Date().getTime() + 12960000000)});

    setShowCookieModal(false);
  };

  return (
      <div>
        <TailwindUiModal
            showCloseButton={false}
            open={showCookieModal}
            reference={modalRef}
            setOpen={setShowCookieModal}
            onClose={() => null}
        >
          <div className={'flex flex-col gap-5'}>
            <div className="text-left text-base flex flex-col">
              <h2 className="text-black font-medium text-center mb-2"> {translateCommon(
                  'COOKIE.TITLE')} </h2>
              <p className="text-gray-500 font-light text-center">{translateCommon(
                  'COOKIE.CONTENT',
                  {cookieUrl: `(https://amptech.store/datenschutzerklaerung)`})}</p>
            </div>
            <div className={'flex flex-row justify-evenly'}>

              <button
                  className={`transition duration-500 ease-in-out rounded-md px-6 py-3 text-sm text-gray-600`}
                  onClick={(e) => handleAcceptCookies(e, false)}>
                {translateCommon('COOKIE.ACCEPT_REQUIRED_ONLY')}
              </button>

              <button
                  className={`text-sm bg-blue-700 text-white px-5 transition duration-500 ease-in-out rounded-md drop-shadow-md filter px-6 py-3`}
                  onClick={(e) => handleAcceptCookies(e, true)}>
                {translateCommon('COOKIE.ACCEPT_ACCEPT_BUTTON')}
              </button>

            </div>
          </div>
        </TailwindUiModal>

        <TailwindUiModal
            showCloseButton={true}
            open={showCookieNotActiveModal}
            reference={modalRef}
            onClose={() => null}
            setOpen={setShowCookieNotActiveModal}>
          <div className={'text-sm'}>{translateCommon(
              'COOKIE.NOT_ACTIVE_TEXT')}</div>
        </TailwindUiModal>

        {/*FB Pixel*/}
        <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
            }}
        />

        {/*Google Tag Manager*/}
        <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5DXRPNV');
          `,
            }}
        />
        {/*Hotjar*/}
        <Script
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1783636,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
            }}
        />
        {/*Google Ads*/}
        <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        {/*Google Analytics*/}
        <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
            }}
        />
        <Toaster
            position="bottom-left"
        />

        {showCustomerLayout && <MainLayout
            currentPath={currentPath}
            isAutoScrolling={isAutoScrolling}>
          {children}
        </MainLayout>}

        {!showCustomerLayout &&
        <DashboardLayout isAutoScrolling={isAutoScrolling}>
          {children}
        </DashboardLayout>}
      </div>
  );
}
