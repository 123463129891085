// const environment = process.env.NODE_ENV;
const environment = 'production';

let clientConfig = {
  siteUrl: 'http://localhost:3000',
  graphqlUrl: 'http://localhost:1337/graphql',
  serverBaseUrl: 'http://localhost:1337',
  // serverBaseUrl: 'https://test-amptech-strapi-2ujpl.ondigitalocean.app',
};

if (environment === 'production') {
  clientConfig = {
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
    graphqlUrl: process.env.GRAPHQL_URL,
    serverBaseUrl: process.env.NEXT_PUBLIC_API_URL,
  };

}

module.exports = clientConfig;
