import Router from 'next/router';
import Cookie from 'js-cookie';
import axios from 'axios';
import clientConfig from '../../../client.config';

const API_URL = clientConfig.serverBaseUrl;

export const findUserDiscountAsync = (jwt) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/discounts/my`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(async response => {

          const {} = response.data;

          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const checkUserAsync = () => {

  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    const jwt = Cookie.get('token');
    if (!!!jwt) reject('No jwt');

    axios.get(`${API_URL}/users/me`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(async response => {

          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });

};

export const updateUserDataAsync = (data) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  const jwt = Cookie.get('token');
  const jwtExists = !!jwt;

  const headers = jwtExists ? {
    'Authorization': `Bearer ${jwt}`,
  } : {};

  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/userData`, {data}, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const updateUserPasswordAsync = (data) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  const jwt = Cookie.get('token');
  const jwtExists = !!jwt;

  const headers = jwtExists ? {
    'Authorization': `Bearer ${jwt}`,
  } : {};

  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/userData/password`, {data}, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const updateUserEmailAsync = (data) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  const jwt = Cookie.get('token');
  const jwtExists = !!jwt;

  const headers = jwtExists ? {
    'Authorization': `Bearer ${jwt}`,
  } : {};

  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/userData/email`, {data}, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const getUsersPaginated = (start, limit) => {
  //prevent function from being ran on the server

  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    const jwt = Cookie.get('token');
    if (!!!jwt) {
      reject('Can not find jwt');
    }

    axios.get(
        `${API_URL}/users?_limit=${limit}&_start=${start}&_sort=id:DESC`,
        {
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        }).
        then(async response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const countUsersAsync = () => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    const jwt = Cookie.get('token');
    if (!!!jwt) {
      reject('Can not find jwt');
    }
    axios.get(`${API_URL}/users/count/`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(async response => {

          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const getUserRoles = () => {
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    const jwt = Cookie.get('token');
    if (!!!jwt) {
      reject('Can not find jwt');
    }
    axios.get(`${API_URL}/users-permissions/roles`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(async response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const createNewUserAsync = (data) => {

  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    const jwt = Cookie.get('token');
    if (!!!jwt) {
      reject('Can not find jwt');
    }

    axios.post(`${API_URL}/userData/createUser`, {data}, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const requestPasswordReset = (data) => {

  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    console.log({...data});

    axios.post(`${API_URL}/auth/forgot-password`, {...data}, {}).
        then(response => {
          console.log(response);
          resolve(response.data);
        }).
        catch((error) => {
          console.error(error);
          reject(error);
        });
  });
};
export const resetPassword = (data) => {

  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    console.log({...data});

    axios.post(`${API_URL}/auth/reset-password`, {...data}, {}).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};