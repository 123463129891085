export const paths = {
  CANCELLATION: 'cancellations',
  ORDERS: 'orders',
  INVOICES: 'invoices',
  IMPRESSUM: 'impressum',
  HOME: 'home',
  AGB: 'agb',
  CATEGORIES: 'categories',
  DATENSCHUTZERKLAERUNG: 'datenschutzerklaerung',
  WIDERRUFSBELEHRUNG: 'widerrufsbelehrung',
  COUNTRIES: 'countries',
  MICRO_PRODUCTS: 'micro-products',
  MACRO_PRODUCTS: 'macro-products',
  VARIATION_PRODUCTS: 'variation-products',
  INSTALLATION_SERVICES: 'installation-services',
  INSTALLATION_EVENTS: 'installation-events',
  SIMPLE_PRODUCTS: 'simple-products',
  SHIPPING_COURIERS: 'shipping-couriers',
  PARTNER_WORKSHOPS: 'partner-workshops',
  REVIEWS: 'reviews',
  SHIPPING: 'shippings',
  AMP_PARTNER: 'amp-partners',
  FILE_COLLECTIONS: 'file-collections',
  PAYMENT_METHOD: 'payment-methods',
  USERS: 'users',
  REFERRALS: 'referrals',
  STOCKS: 'stocks',
  LOCATION_ITEMS: 'location-items',
  FAQ: 'faqs',
  STRIPE: 'stripe',
  STRIPE_PAYMENT_INTENT: 'stripe/paymentIntent',
  SHIPPING_TIMES: 'shipping-times',
  SETTINGS: 'settings',
  SUPPLIER_ORDERS: 'supplier-orders',
  STOCK_LOCATION: 'location-items',
  SUPPLIER: 'suppliers',
  NEWSLETTER_USERS: 'newsletter-users',
  TEMPS: 'temps',
  TEMPS_UPDATE_CREATE: 'temps/update-or-create',
  TEMPS_FIND_BY_CLIENT_SECRET: 'temps/clientSecret',
  EXTERNAL_ORDER:'external-orders'
};
