import React, {useState} from 'react';
import {useTransition} from '@react-spring/web';
import {useTranslation} from 'next-i18next';

const CustomerHelp = ({}) => {
  const {t: translateCommon} = useTranslation('common');

  const [showFull, setShowFull] = useState(false);

  const onToggleArea = (e) => {

    e.preventDefault();
    setShowFull(!showFull);

  };

  const transitions = useTransition(showFull, {
    from: {},
    enter: {},
    leave: {},
  });

  return <div
      className={`absolute right-10 bottom-28 lg:bottom-28 rounded-full bg-emerald-800 border-emerald-900 border-2 h-16 text-emerald-100 flex flex-row items-center justify-center z-20 transition-all duration-500 shadow-2xl ${showFull ?
          'w-72 ' :
          'w-16 hover:bg-emerald-700 cursor-pointer'}`}
      onClick={(e) => showFull ? null : onToggleArea(e)}>

    <svg style={{width: '1.5rem', height: '1.5rem'}}
         fill="currentColor" viewBox="0 0 20 20"
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
    </svg>
    <a href={`tel:${translateCommon('HOTLINE')}`} className={`ml-2 ${showFull ?
        'opacity-100 cursor-pointer' :
        'opacity-0 hidden'}`}><p
        className={'text-sm font-semibold underline '}> {translateCommon(
        'HOTLINE')} </p>
      <p className={'text-tiny w-48'}> {translateCommon(
          'CONSULTATION_BETWEEN')} </p>
    </a>
    {showFull && <svg onClick={onToggleArea}
                      className="w-6 h-6 cursor-pointer" fill="none"
                      stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"/>
    </svg>
    }
  </div>;
};

export default CustomerHelp;
