import React, {useState, useRef, useEffect, useContext} from 'react';
import qs from 'qs';

import Transition from '../../../../lib/utils/dashboardUtils/Transition';
import {findAsync} from '../../../../lib/api/rest/strapi';
import {paths} from '../../../../lib/api/rest/paths';
import {useRouter} from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import {
  handleRouteToJowuaProduct,
  handleRouteToMacroProduct,
} from '../../../../lib/utils/productUtils';
import {SearchContext} from '../../../../utils/context/appContext';
import {useTranslation} from 'next-i18next';

// import Transition from '../../utils/Transition.js';

function SearchModal({className = ''}) {

  const [searchOpen, setSearchOpen] = useState(false);
  const trigger = useRef(null);
  const searchContent = useRef(null);
  const searchInput = useRef(null);
  const [resultList, setResultList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useContext(SearchContext);

  const {locale, defaultLocale} = useRouter();
  const {t: translateCommon} = useTranslation('common');

  // close on click outside
  useEffect(() => {
    const clickHandler = ({target}) => {
      if (!searchOpen || searchContent.current.contains(target) ||
          trigger.current.contains(target)) return;
      setSearchOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!searchOpen || keyCode !== 27) return;
      setSearchOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleOnChangeInput = async (e) => {
    try {
      e.preventDefault();

      const {value} = e.target;
      setSearchValue(value);

      if (value.length === 0)
        setResultList([]);

      if (value.length < 2) return;

      const query = {_limit: 4, _q: value};
      const intl = defaultLocale !== locale;
      if (intl)
        query['_locale'] = locale;

      const queryString = qs.stringify(query);
      const macroProductResponse = await findAsync(
          paths.MACRO_PRODUCTS,
          false,
          queryString,
      );
      const variationProductResponse = await findAsync(
          paths.VARIATION_PRODUCTS,
          false,
          queryString,
      );

      let macroProductList = [];
      if (!!macroProductResponse)
        macroProductList = [
          ...macroProductResponse.map((product) => {

            const type = 'macro';
            const {title, slug, media, category, id} = product;
            const {wireframe: img} = media;

            return {
              title, slug, img, category, type, id,
            };

          })];

      let variationProductList = [];
      if (!!variationProductResponse)
        variationProductList = [
          ...variationProductResponse.map((product) => {

            const type = 'variation';
            const {title, slug, simpleProductInformation, id} = product;
            const _title = title.replace('{{model}}', '');
            const {mainImage: img} = simpleProductInformation;

            return {
              title: _title, slug, img, type, id,
            };

          })];
      const resultList = [...macroProductList, ...variationProductList];
      setResultList(resultList);

    } catch (e) {
      console.error(e);
    }

  };

  const handleRouteToProduct = async (e, product) => {

    e.preventDefault();

    setSearchOpen(false);
    setResultList([]);
    setSearchValue('');

    const {type} = product;

    const searchList = [product, ...search.list].slice(0, 5);
    const _search = {
      list: searchList,
    };
    setSearch(_search);

    if (type.includes('macro'))
      await handleRouteToMacroProduct(e, product, locale);

    if (type.includes('variation'))
      await handleRouteToJowuaProduct(e, product, locale);

  };

  return (
      <div className={`block w-full max-h-full  ${className}`}>
        {/* Button */}
        <button
            ref={trigger}
            className={`h-8 px-2 flex items-center duration-150 sm:hidden ml-auto bg-gray-100 shadow-inner shadow-md w-32 mx-auto rounded ${!!searchOpen &&
            'hidden'}`}
            onClick={() => {
              setSearchOpen(!searchOpen);
              setTimeout(() => searchInput.current.focus());
            }}
            aria-controls="search-modal"
        >
          <span className="sr-only">Search</span>
          <svg
              className={`w-5 h-5 sm:w-6 sm:h-6 hover:text-white text-gray-400 transition-colors mr-2 ${searchOpen &&
              'text-white'}`} fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
          </svg>
          <span className={'text-sm text-gray-700'}>
          {translateCommon('BTN.SEARCH')}
          </span>
        </button>
        {/* Modal backdrop */}
        <Transition
            className="fixed inset-0 bg-gray-900 bg-opacity-30 z-40 transition-opacity"
            show={searchOpen}
            enter="transition ease-out duration-200"
            enterStart="opacity-0"
            enterEnd="opacity-100"
            leave="transition ease-out duration-100"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            aria-hidden="true"
        />


        {/* Modal dialog */}
        <div
            id="search-modal"
            className={`fixed w-72 left-8 sm:left-0 top-1.5 sm:block sm:w-full inset-0 z-50 overflow-hidden flex items-start top-1.5 mb-4 transform px-4 sm:px-6 mx-auto relative ${!!!searchOpen &&
            'hidden'}`}
        >
          <div
              className={` bg-white overflow-auto max-w-2xl lg:max-w-full w-full max-h-full rounded shadow-lg transition-opacity`}
              ref={searchContent}>
            {/* Search form */}
            <form className="border-b border-gray-200">
              <div className="relative">
                <label htmlFor="modal-search"
                       className="sr-only">Search</label>
                <input id="modal-search"
                       className="w-full border-0 focus:ring-transparent placeholder-gray-400 appearance-none py-1.5 pl-10 pr-4 text-sm"
                       type={'text'}
                       autoComplete={'none'}
                       placeholder={translateCommon('BTN.SEARCH')}
                       value={searchValue}
                       ref={searchInput}
                       onChange={handleOnChangeInput}
                       onClick={() => {
                         setSearchOpen(true);
                       }}
                />
                <button className="absolute inset-0 right-auto group"
                        type="submit" aria-label="Search">
                  <svg
                      className="w-3 h-3 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-4 mr-2"
                      viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/>
                    <path
                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"/>
                  </svg>
                </button>
              </div>
            </form>
            <Transition
                // id="search-modal"
                className=""
                role="dialog"
                aria-modal="true"
                show={searchOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
              <div className="py-4 px-2" onFocus={() => setSearchOpen(true)}
                   onBlur={() => setSearchOpen(false)}>
                {/* Recent searches */}
                <div className="mb-3 last:mb-0">
                  <div
                      className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">{translateCommon(
                      'SEARCH_RESULTS')}
                  </div>
                  <ul className={'text-sm flex flex-col gap-3'}>
                    {resultList.map((product) => {
                      const {title, img, id, type} = product;

                      const key = `${type}_${id}`;
                      return <button
                          key={key}
                          onClick={(e) => handleRouteToProduct(e, product)}
                          className={'flex flex-row items-center gap-3 hover:bg-gray-50 p-1 rounded'}>
                        <div
                            className={'h-12 w-12 border border-solid border-gray-200 rounded'}>
                          <Image src={img.url}
                                 alt={img.alternativeText}
                                 height={img.height}
                                 width={img.width}
                                 layout={'responsive'}
                                 quality={100}/>

                        </div>
                        <h3>{title}</h3>
                      </button>;

                    })}
                  </ul>
                </div>
                {/* TODO: Recent pages */}
                {/*{!!search && !!search.list && <div className="mb-3 last:mb-0">*/}
                {/*  <div*/}
                {/*      className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Recent*/}
                {/*    pages*/}
                {/*  </div>*/}
                {/*  <ul className={'text-sm flex flex-col gap-3'}>*/}
                {/*    {search.list.map((product) => {*/}
                {/*      const {title, img, id, type} = product;*/}

                {/*      const key = `last_${type}_${id}`;*/}
                {/*      return <button*/}
                {/*          key={key}*/}
                {/*          onClick={(e) => handleRouteToProduct(e, product)}*/}
                {/*          className={'flex flex-row items-center gap-3 hover:bg-gray-50 p-1 rounded'}>*/}
                {/*        <div*/}
                {/*            className={'h-12 w-12 border border-solid border-gray-200 rounded'}>*/}
                {/*          <Image src={img.url}*/}
                {/*                 alt={img.alternativeText}*/}
                {/*                 height={img.height}*/}
                {/*                 width={img.width}*/}
                {/*                 layout={'responsive'}*/}
                {/*                 quality={100}/>*/}

                {/*        </div>*/}
                {/*        <h3>{title}</h3>*/}
                {/*      </button>;*/}

                {/*    })}*/}
                {/*  </ul>*/}
                {/*</div>}*/}
              </div>
            </Transition>

          </div>
        </div>

      </div>

  );
}

export default SearchModal;
