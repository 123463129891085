import ContainedButton from '../buttons/containedButton';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {ModalContext, UIContext} from '../../utils/context/appContext';
import {useRouter} from 'next/router';
import {createAsync} from '../../lib/api/rest/strapi';
import {paths} from '../../lib/api/rest/paths';
import {combineClassNames} from '../../lib/utils/generalUtils';

const NewsLetterForm = ({
                          className = '',
                          showCloseBtn = true,
                          useH1 = false,
                        }) => {
  const {t: translateCommon} = useTranslation('common');

  const [modal, setModal] = useContext(ModalContext);
  const [ui, setUi] = useContext(UIContext);

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessFul, setIsSuccessful] = useState(false);

  const {locale} = useRouter();

  const setNewsletterModal = (value) => {

    let _ui = {
      ...ui,
    };

    _ui.newsletterModalIsOpen = value;

    setUi(_ui);

  };

  const disableModal = () => {

    let _modal = {
      ...modal,
    };

    _modal.showNewsletterModal = false;
    setModal(_modal);
  };

  const onCloseNewsletterModal = () => {
    disableModal();
    setNewsletterModal(false);

  };

  const handleSubscribeToNewsletter = (e) => {

    e.preventDefault();

    setIsLoading(true);

    const data = {
      locale,
      email,
    };

    createAsync(data, paths.NEWSLETTER_USERS).then(response => {
      // setNewsletterModal(false);

      setIsSuccessful(true);
      setIsLoading(false);
      disableModal();

    }).catch(err => {
      setIsLoading(false);
    });

  };
  return <div
      className={combineClassNames(
          'relative px-6 pb-10 sm:pb-20 bg-red-900 overflow-hidden shadow-xl sm:px-12',
          className)}>
    <div aria-hidden="true"
         className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
      <svg
          className="absolute inset-0 h-full w-full"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1463 360"
      >
        <path
            className="text-red-500 text-opacity-40"
            fill="currentColor"
            d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
        />
        <path
            className="text-red-700 text-opacity-40"
            fill="currentColor"
            d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
        />
      </svg>
    </div>
    <div className="relative">
      <div className={'h-14 flex flex-row items-center'}>
        {showCloseBtn && <svg onClick={(e) => onCloseNewsletterModal()}
                              className="w-10 h-10 ml-auto text-red-200 hover:text-white cursor-pointer transition-colors"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round"
                strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>}
      </div>

      <div className="sm:text-center">
        {useH1 ? <h1 className="text-3xl font-extrabold text-white sm:text-4xl">
          {translateCommon('FORMS.NEWSLETTER_TITLE')}
        </h1> : <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          {translateCommon('FORMS.NEWSLETTER_TITLE')}
        </h2>}

        <p className="mt-6 mx-auto max-w-2xl text-lg text-red-200">
          {translateCommon('FORMS.NEWSLETTER_DESCRIPTION')}
        </p>
      </div>
      <form onSubmit={handleSubscribeToNewsletter}
            className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
        <div className="min-w-0 flex-1">
          <label htmlFor="cta-email" className="sr-only">
            Email address
          </label>
          <input
              id="cta-email"
              type="email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600"
              placeholder={translateCommon('FORMS.EMAIL_ADDRESS')}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-3">
          <ContainedButton secondary isLoading={isLoading}
                           disabled={isSuccessFul}>
            {translateCommon('BTN.SUBSCRIBE')}
          </ContainedButton>

        </div>
      </form>
      {isSuccessFul &&
      <p className={'mt-2 text-sm text-red-100 font-semibold text-center'}> {translateCommon(
          'FORMS.NEWSLETTER_CONFIRM_VIA_BTN_IN_MAIL')}</p>}
    </div>
  </div>;

};

export default NewsLetterForm;
