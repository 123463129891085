import * as fs from 'fs';
import * as path from 'path';

import {isObjectEmpty} from './objectUtils';

export const paginationAmount = () => {
  return {
    DEFAULT: 30, BIG: 100,
  };
};

export const checkIfUndefined = (value) => {

  return typeof value === 'undefined';

};

export const checkIfObjectHasKeys = (obj) => {

  if (!!!obj) return false;
  return Object.keys(obj).length > 0;

};

export const checkIfKeyExistsInObj = (key, obj) => {

  return (key in obj);

};

export const showNativeQuestionPopup = (question, acceptCallback) => {

  if (confirm(question)) {

    acceptCallback();

  }

};

export const getDescendantProp = (obj, desc) => {

  if (!!!obj || isObjectEmpty(obj) || !!!desc) return false;

  const arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()])) ;
  return obj;
};

export const convertMinutesToMs = (minutes) => {

  return minutes * 60000;

};

export const findSettings = (key, settingsList) => {

  console.log(settingsList);

  const setting = settingsList.find(
      ({key: settingsKey}) => settingsKey === key);

  const {type, value} = setting;

  if (type === 'NUMBER') return {
    ...setting, value: parseInt(value),
  };

  if (type === 'FLOAT') return {
    ...setting, value: parseFloat(value),
  };

  if (type === 'BOOLEAN') return {
    ...setting, value: value === 'true',
  };

  return setting;

};

export const combineClassNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const getAvailableLocals = () => {

  return ['de', 'en', 'es'];

};

export const getAllFiles = function(dirPath, arrayOfFiles) {
  const files = fs.readdirSync(dirPath);
  arrayOfFiles = arrayOfFiles || [];

  files.forEach(function(file) {
    if (fs.statSync(dirPath + '/' + file).isDirectory()) {
      arrayOfFiles = getAllFiles(dirPath + '/' + file, arrayOfFiles);
    } else {
      arrayOfFiles.push(path.join(dirPath, '/', file));
    }
  });

  return arrayOfFiles;
};
