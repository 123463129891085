import MainSidebar from './MainSidebar';
import Cart from './cart';
import MainHeader from './MainHeader';
import {closeAll} from '../../lib/utils/uiUtils';
import Footer from '../footer';
import {checkIfUserHasToken} from '../../lib/utils/routeUtils';
import {checkUserAsync} from '../../lib/api/rest/users';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  CartContext, ModalContext,
  UIContext,
  UserContext,
} from '../../utils/context/appContext';
import CustomerHelp from './CustomerHelp';
import {useTranslation} from 'next-i18next';
import TailwindUiNewsletterModal from './modals/TailwindUiNewsletterModal';
import {useRouter} from 'next/router';

export const MainLayout = ({

                             currentPath,
                             children,
                             isAutoScrolling,
                           }) => {

  const {asPath} = useRouter();

  const [user, setUser] = useContext(UserContext);
  const [ui, setUi] = useContext(UIContext);
  const [cart, setCart] = useContext(CartContext);
  const reference = useRef();

  useEffect(() => {

    const userHasToken = checkIfUserHasToken();

    if (userHasToken)
      checkUserAsync().then(response => {

        const _user = {
          data: response,
          isAuthenticated: true,
        };

        setUser(_user);

      }).catch(err => {

        const _user = {
          data: null,
          isAuthenticated: false,
        };

        setUser(_user);

      });

    // Show newsletter modal after 10 seconds

    // const showModalTimeout = setTimeout(() => {
    //
    //   if (showNewsletterModal)
    //     setNewsletterModal(true);
    //
    // }, 10000);

  }, []);

  const isInCheckoutPage = asPath.toLowerCase().includes('checkout');
  // console.log('Is in checkout page', isInCheckoutPage);

  const setNewsletterModal = (value) => {

    let _ui = {
      ...ui,
    };

    _ui.newsletterModalIsOpen = value;

    setUi(_ui);

  };

  return <div id={'main-layout-wrapper'}
              ref={reference}
              className={` ${isAutoScrolling ?
                  '' :
                  'overflow-hidden h-screen flex'} `}>

    <TailwindUiNewsletterModal reference={reference}/>

    {/*<StickyFooter ui={ui} setUi={setUi}/>*/}

    {/* Sidebar */}
    <MainSidebar
        ui={ui}
        setUi={setUi}
        sidebarOpen={ui.sideMenuIsOpen}
        userData={user.data}
    />

    <Cart
        ui={ui}
        setUi={setUi}
        cart={cart}
        setCart={setCart}/>

    <CustomerHelp/>

    {/* Content area */}
    <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

      {/*  Site header */}
      {!isInCheckoutPage && <MainHeader ui={ui} setUi={setUi}/>}

      {/*If sidebar or cart is open, the page is not scrollable anymore*/}
      <div onClick={(e) => closeAll(e, ui, setUi)}
           className={`${ui.sideMenuIsOpen || ui.cartIsOpen ?
               'bg-black duration-200 opacity-50 w-screen h-screen z-30 fixed transition-all' :
               'hidden'}`}/>
      <main>
        {children}
      </main>
      <Footer currentPath={currentPath}/>

    </div>
  </div>;

};
