import {LoadingCircle} from '../../animations/loadingCircle';
import TextButton from '../../buttons/textButton';
import TailwindUiModal from '../TailwindUiModal';
import {useTranslation} from 'next-i18next';
import ContainedButton from '../../buttons/containedButton';
import React, {useContext, useState} from 'react';
import {ModalContext, UIContext} from '../../../utils/context/appContext';
import {createAsync} from '../../../lib/api/rest/strapi';
import {paths} from '../../../lib/api/rest/paths';
import {useRouter} from 'next/router';
import NewsletterForm from '../../forms/NewsletterForm';

const TailwindUiNewsletterModal = ({
                                     reference,
                                   }) => {
  const [modal, setModal] = useContext(ModalContext);
  const [ui, setUi] = useContext(UIContext);

  const {newsletterModalIsOpen} = ui;

  const setNewsletterModal = (value) => {

    let _ui = {
      ...ui,
    };

    _ui.newsletterModalIsOpen = value;

    setUi(_ui);

  };

  const disableModal = () => {

    let _modal = {
      ...modal,
    };

    _modal.showNewsletterModal = false;
    setModal(_modal);
  };

  const onCloseNewsletterModal = () => {
    disableModal();
    setNewsletterModal(false);

  };

  return <TailwindUiModal setOpen={setNewsletterModal}
                          open={newsletterModalIsOpen}
                          showCloseButton={false}
                          showPadding={false}
                          reference={reference}
                          onClose={onCloseNewsletterModal}
  >
    <NewsletterForm/>

  </TailwindUiModal>;

};

export default TailwindUiNewsletterModal;
