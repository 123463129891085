import Router from 'next/router';
import Cookie from 'js-cookie';
import axios from 'axios';
import clientConfig from '../../../client.config';
import {findAsync} from './strapi';
import {paths} from './paths';

const API_URL = clientConfig.serverBaseUrl;

//register a new user
export const registerUser = (username, email, password) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/auth/local/register`, {username, email, password}).
        then(async (res) => {
          //set token response from Strapi for server validation
          Cookie.set('token', res.data.jwt);

          //resolve the promise to set loading to false in SignUp form
          resolve(res);
          //redirect back to home page for restaurance selection
          await Router.push('/');
        }).
        catch((error) => {
          //reject the promise and pass the error object back to the form
          reject(error);
        });
  });
};

export const login = (identifier, password) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/auth/local/`, {identifier, password}).
        then(async response => {

          const {jwt, user} = response.data;
          Cookie.set('token', jwt);

          if (!!response.data.user.orders) {
            delete response.data.user.orders;
          }

          resolve(response.data);

        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const logout = async (routeAfterLogout = '/', setUser) => {
  //remove token and user cookie
  Cookie.remove('token');
  // localStorage.setItem('user',
  //     JSON.stringify({isAuthenticated: false, data: null}));
  // delete window.__user;
  // sync logout between multiple windows
  setUser({data: null, isAuthenticated: false});
  window.localStorage.setItem('logout', Date.now());
  await Router.reload(routeAfterLogout);
};