import React, {useContext, useEffect} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {CartContext, UserContext} from '../../utils/context/appContext';
import AMPTechLogoSvg from '../svg/amptechLogoSvg';
import {toggleCart, toggleSideBar} from '../../lib/utils/uiUtils';
import LanguageMenu from './languageMenu';
import SearchModal from '../dashboard/partials/header/SearchModal';

export default function MainHeader({
                                     ui,
                                     setUi,
                                   }) {

  const [user, setUser] = useContext(UserContext);
  const [cart, setCart] = useContext(CartContext);
  const router = useRouter();
  const {locale} = router;

  const {isAuthenticated, data: userData} = user;

  const handleOnClickToProfile = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {

      await router.push('/login');

    } else {

      const {type} = userData.role;
      const routeToDashboard = type.includes('admin') ||
          type.includes('partner');

      await router.push(routeToDashboard ? '/dashboard' : '/account');

    }

  };

  const cartIsNotEmpty = !!cart && !!cart.lineItems &&
      cart.lineItems.length > 0;

  return (
      <header className="sticky top-0 bg-black z-40 h-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-12 -mb-px">
            <Link
                href={`/${locale}`}>
              <a className={'w-56 cursor-pointer'}>
                <AMPTechLogoSvg light={true} type={'simple'}/>
              </a>
            </Link>
              <SearchModal />
            {/* Header: Right side */}
            <div className="flex justify-end items-center w-56">

              {/*<Notifications />*/}
              {/*<Help />*/}
              {/*  Divider */}
              {/*<hr className="w-px h-6 bg-gray-200 mx-3"/>*/}

              {/*{!!data ? <UserMenu user={user}/> : <></>}*/}
              {/* Hamburger button */}
              <LanguageMenu className={'hidden md:flex mr-5'} router={router}/>

              <button className={'block w-4 mr-5'}
                      onClick={handleOnClickToProfile}>

                <svg
                    className={'w-5 h-5 md:w-6 md:h-6 hover:text-white text-gray-400 transition-colors'}
                    fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                </svg>

              </button>
              <button onClick={(e) => toggleCart(e, ui, setUi)}
                      className={'block mr-5'}>
                <div className={'relative bottom-1.5'}>

                  <div
                      className={'h-3 w-3 bg-amber-400 relative top-1 left-3 rounded-full border border-amber-200 transition'}>
                    <span
                        className={'text-tiny text-black font-semibold text-center relative bottom-2.5'}> {
                      cartIsNotEmpty ?
                          cart.lineItems.length :
                          0
                    }</span>
                  </div>

                  <svg
                      className={`w-5 h-5 md:w-6 md:h-6 hover:text-white transition ${cartIsNotEmpty ?
                          'text-white' :
                          'text-gray-400'}`}
                      fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
                  </svg>


                </div>
              </button>

              <button onClick={(e) => toggleSideBar(e, ui, setUi)}>
                <div id="nav-icon3"
                     className={'w-24px h-16px relative my-auto transform rotate-0 transition cursor-pointer'}>
                <span
                    className={`block absolute h-2px  bg-white rounded opacity-100  transform rotate-0 transition top-0 duration-300 ${ui.sideMenuIsOpen ?
                        'top-18px w-0 left-1/2' :
                        'l-0 w-full'}`}/>
                  <span
                      className={`block absolute h-2px w-full bg-white rounded opacity-100 l-0 transform  transition duration-300 top-7px ${ui.sideMenuIsOpen ?
                          'rotate-45' :
                          'rotate-0'}`}/>
                  <span
                      className={` block absolute h-2px w-full bg-white rounded opacity-100 l-0 transform  transition duration-300 top-7px ${ui.sideMenuIsOpen ?
                          '-rotate-45' :
                          'rotate-0'}`}/>
                  <span
                      className={` block absolute h-2px  bg-white rounded opacity-100  transform rotate-0 transition  duration-300 top-14px ${ui.sideMenuIsOpen ?
                          'top-18px w-0 left-1/2' :
                          'l-0 w-full'}`}/>
                </div>
              </button>


            </div>

          </div>
        </div>
      </header>
  );
}