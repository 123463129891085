import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import SocialMedia from './socialMedia';
import PaymentMethods from './paymentMethods';
import {footerPaths} from '../lib/routes';
import AMPTechLogoSvg from './svg/amptechLogoSvg';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';

export default function Footer({currentPath}) {

  const {locale} = useRouter();
  const {t: translateMenu} = useTranslation('menu');
  const {col2Items, col3Items, col4Items} = footerPaths(translateMenu, locale);

  const col2 = col2Items.map((link) =>
      <li key={link.text.replace(' ', '-')}
          className={'pb-2 hover:text-red-400 transition hover:text-red-400 transition'}>
        {link.external}
        <Link href={link.url}>
          <a href={link.url}>
            {link.text}
          </a>
        </Link>
      </li>,
  );

  const col3 = col3Items.map((link) =>
      <li key={link.text.replace(' ', '-')}
          className={'pb-2 hover:text-red-400 transition hover:text-red-400 transition'}>
        {link.external}
        <Link href={link.url}>
          <a href={link.url}>
            {link.text}
          </a>
        </Link>
      </li>,
  );
  const col4 = col4Items.map((link) =>
      <li key={link.text.replace(' ', '-')}
          className={'pb-2 hover:text-red-400 transition hover:text-red-400 transition'}>
        {link.external}
        <a href={link.url}>
          {link.text}
        </a>
      </li>,
  );

  return (
      <footer
          className={`text-white h-auto flex flex-col items-center border-t-2 border-gray-600 z-40 relative ${currentPath.includes(
              'checkout') ? 'hidden' : ''}`}>
        <div className={'bg-gray-900 items-center flex-col flex'}>
          <div className={'w-4/5  '}>
            <center>
              <div className={'w-40 py-6'}>

                <div className={'w-40'}>
                  <AMPTechLogoSvg type={'big'} light={true}/>
                </div>
                {/*<Image src={logo} alt={'AMPTech Logo'} height={534} width={1200}*/}
                {/*       layout={'responsive'}*/}
                {/*       quality={100}/>*/}
              </div>
            </center>
            <div
                className={'flex flex-col md:grid md:gap-4 md:grid-cols-2 lg:grid-cols-4 py-5'}>
              <div className={'mb-5 md:mb-0 p-5 text-center md:text-left'}>
                <h3 className={'mb-4 uppercase text-tiny border-b pb-4 border-gray-700'}> {translateMenu(
                    'COL_1_TITLE')} </h3>
                <p className={'font-body text-xs font-light'}>
                  {translateMenu(
                      'COL_1_CONTENT')}
                </p>
                <p className={'font-body text-xs font-light mt-4'}>{translateMenu(
                    'GO_TO_CONTACT_PAGE')}<span
                    className={'font-medium hover:text-red-400 transition'}><Link
                    href={`/contact`}>
                {/*TODO Internationalization*/}
                  <a href={'/contact'}> https://www.amptech.store/contact</a>
                        </Link></span></p>
              </div>
              <div className={'mb-5 md:mb-0 p-5 text-center md:text-left'}>
                <h3 className={'mb-4 uppercase text-tiny border-b pb-4 border-gray-700'}>{translateMenu(
                    'COL_2_TITLE')}</h3>
                <ul className={'font-body text-xs font-light '}>
                  {col2}
                </ul>
              </div>
              <div className={' mb-5 md:mb-0 p-5 text-center md:text-left'}>
                <h3 className={'mb-4 uppercase text-tiny border-b pb-4 border-gray-700'}>{translateMenu(
                    'COL_3_TITLE')}</h3>
                <ul className={'font-body text-xs font-light'}>
                  {col3}
                </ul>
              </div>
              <div className={' mb-5 md:mb-0 p-5 text-center md:text-left'}>
                <h3 className={'mb-4 uppercase text-tiny border-b pb-4 border-gray-700'}>{translateMenu(
                    'COL_4_TITLE')}</h3>
                <ul className={'font-body text-xs font-light'}>
                  {col4}
                  <SocialMedia/>
                </ul>
              </div>

            </div>

          </div>
        </div>
        <div className={'bg-black w-full'}>

          <div className={'flex flex-col items-center'}>

            <div className={'w-4/5'}>


              <div
                  className={'flex flex-col md:flex-row items-center md:items-start md:justify-between py-2'}>
                <span
                    className={'text-xs my-auto py-3 md:py-0 text-center md:text-left'}>© AMPTech 2021. Alle Rechte vorbehalten.</span>
                <span className={'w-56 my-auto'}>
                <PaymentMethods/>

            </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );

}
