export const QUESTION_TYPE = {
  LABEL: 'LABEL',
  COLOR: 'COLOR',
  BUNDLE: 'BUNDLE',
  INSTALLATION: 'INSTALLATION',
};

export function parseQuestionType(questionType) {

  switch (questionType) {

    case QUESTION_TYPE.LABEL:
      return QUESTION_TYPE.LABEL;

    case QUESTION_TYPE.COLOR:
      return QUESTION_TYPE.COLOR;

    case QUESTION_TYPE.BUNDLE:
      return QUESTION_TYPE.BUNDLE;

    case QUESTION_TYPE.INSTALLATION:
      return QUESTION_TYPE.INSTALLATION;

  }

}