import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import clientConfig from './client.config';
import {setContext} from '@apollo/client/link/context';

const client = new ApolloClient({
  uri: clientConfig.graphqlUrl,
  cache: new InMemoryCache(),
});


export default client;

export const authClient = (token) => {

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

};