import {createOrderItems} from '../../lib/utils/cartUtils';
import {useEffect, useState} from 'react';
import CartOrderItem from './cartOrderItem';
import {useTranslation} from 'next-i18next';
import {EmptyCart} from '../EmptyCart';
import {PRODUCT_TYPE} from "../../lib/models/enum/productTypes";

export default function CartItems({
                                      cart,
                                      setCart,
                                      className = '',
                                      handleOnDeleteCartItem,
                                      shopLineItems,
                                      consultationLineItems
                                  }) {
    const {t: translatePages} = useTranslation('pages');

    const [orderItems, setOrderItems] = useState([]);
    const [consultationOrderItems, setConsultationOrderItems] = useState([]);

    useEffect(() => {

        if (process.browser && !!cart && shopLineItems.length > 0) {

            const orderItems = createOrderItems(shopLineItems);
            setOrderItems(orderItems);

        }

    }, [shopLineItems]);

    useEffect(() => {

        if (process.browser && !!cart && consultationLineItems.length > 0) {

            const consultationOrderItems = createOrderItems(consultationLineItems)
            setConsultationOrderItems(consultationOrderItems)
        }

    }, [consultationLineItems]);

    return <>{!!cart && (!!shopLineItems.length > 0 || consultationLineItems.length > 0) ?
        <>
            {orderItems.map(
                (orderItem, index) => {

                    const {configurationOptions} = cart;
                    const [item] = orderItem;
                    const {cartIndex} = item;

                    return <CartOrderItem
                        index={index}
                        key={`CART_ORDER_ITEM_${index}`}
                        orderItem={orderItem}
                        cart={cart}
                        setCart={setCart}
                        handleOnDeleteCartItem={handleOnDeleteCartItem}
                        configurationOptions={configurationOptions[`CART_INDEX_${cartIndex}`]}
                    />;
                })}

            {consultationOrderItems.length > 0 && <div className={'mt-4 max-w-md pt-4'}>
                <h3 className={'text-sm text-gray-800'}>{translatePages('CHECKOUT.CONSULTATION_PRODUCT.REQUEST_PRICE_TITLE')}</h3>
                <p className={'text-tiny mb-2 text-gray-600'}>{translatePages('CHECKOUT.CONSULTATION_PRODUCT.REQUEST_PRICE_DESCRIPTION')}</p>
                {consultationOrderItems.map(
                    (orderItem, index) => {

                        const {configurationOptions} = cart;
                        const [item] = orderItem;
                        const {cartIndex} = item;

                        return <CartOrderItem
                            index={index}
                            key={`CART_ORDER_ITEM_${index}`}
                            orderItem={orderItem}
                            cart={cart}
                            setCart={setCart}
                            handleOnDeleteCartItem={handleOnDeleteCartItem}
                            configurationOptions={configurationOptions[`CART_INDEX_${cartIndex}`]}
                        />;
                    })}
            </div>}
        </> :
        <div className={'flex flex-col items-start my-4 items-center'}>
            <EmptyCart height={100} width={100}/>
            <p className={'text-sm text-gray-500'}>
                {translatePages('LAYOUT.CART.CART_IS_EMPTY')}
            </p>
        </div>
    }</>;

}
