import React, {useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';

import CartItems from './cartItems';
import SmallCartOverview from './smallCartOverview';
import {toggleCart} from '../../lib/utils/uiUtils';
import ContainedButton from '../buttons/containedButton';
import {useTranslation} from 'next-i18next';
import {animated, useTransition} from '@react-spring/web';
import {PRODUCT_TYPE} from "../../lib/models/enum/productTypes";

export default function Cart({
                                 cart,
                                 setCart,
                                 ui,
                                 setUi,
                             }) {
    const {t: translatePages} = useTranslation('pages');

    const [buttonsAreDisabled, setButtonsAreDisabled] = useState(true);
    useEffect(() => {

        const _buttonsAreAbled = !!cart && cart.lineItems.length > 0;

        setButtonsAreDisabled(!_buttonsAreAbled);

    }, [cart]);

    const router = useRouter();

    const {cartIsOpen} = ui;

    const transitions = useTransition(cartIsOpen, {
        from: {
            y: -1000,
            opacity: 0,
        },
        enter: {
            y: 0,
            opacity: 1,
        },
        leave: {
            y: -1000,
            opacity: 0,
        },
    });

    const cartIsNotEmpty = !!cart && !!cart.lineItems &&
        cart.lineItems.length > 0;

    const shopLineItems = !!cart && !!cart.lineItems ? cart.lineItems.filter(({type}) => type !== PRODUCT_TYPE.CONSULTATION_PRODUCT) : [];
    const consultationLineItems = !!cart && !!cart.lineItems ? cart.lineItems.filter(({type}) => type === PRODUCT_TYPE.CONSULTATION_PRODUCT) : [];

    const onCheckoutClick = async () => {
        toggleCart(null, ui, setUi);
        await router.push(`/cart/smart-wagon`);
    };

    return <>

        {
            transitions((style, show) =>

                show ? <animated.div id={'cart'}
                                     className={`absolute right-0 w-full md:w-auto md:rounded-l-lg max-h-full pt-12 pl-6 bg-white z-40 overflow-y-scroll`}
                                     style={{
                                         ...style,
                                     }}
                >

                    <div className={'pt-2'}>
                        <div className={'flex flex-row justify-end'}>
                            <div className={'flex flex-col'}>
                                <div className={'w-full lg:w-128 pr-6'}>
                                    <div className={'flex flex-row justify-between'}>
                                        <h2 className={'text-2xl text-gray-900'}>
                                            {translatePages('LAYOUT.CART.TITLE')}
                                        </h2>
                                        <button
                                            onClick={(e) => toggleCart(e, ui, setUi)}
                                            className={'transition duration-200 transform group-hover:scale-110 group-hover:bg-white'}>

                                            <svg className="w-6 h-6 text-gray-900" fill="none"
                                                 stroke="currentColor" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </button>

                                    </div>
                                </div>

                                <CartItems cart={cart} setCart={setCart} className={'pr-6'}
                                           consultationLineItems={consultationLineItems} shopLineItems={shopLineItems}/>
                                {!!cart ?
                                    <div className={'flex flex-col'}>
                                        <SmallCartOverview
                                            cart={cart}
                                            className={'pr-6'}/>
                                    </div> :
                                    <></>}
                                <div
                                    className={'py-4 sticky bottom-0 bg-white flex flex-row justify-end pr-6'}>
                                    <ContainedButton size={'sm'}
                                                     onClick={onCheckoutClick}
                                                     disabled={!cartIsNotEmpty}
                                    >
                                        {translatePages('LAYOUT.CART.TO_CHECKOUT')}
                                    </ContainedButton>
                                </div>
                            </div>
                        </div>
                    </div>


                </animated.div> : '',
            )
        }


    </>;

}