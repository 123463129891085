import React from 'react';
import theme from '../../theme';
import CoreButton from './coreButton';

export default function ContainedButton({
                                            ref,
                                            onClick,
                                            isLoading,
                                            secondary,
                                            primary = true,
                                            disabled = false,
                                            preventDefault,
                                            className = '',
                                            size = 'base',
                                            children,
                                            light = true,
                                            customBg = '',
                                            customBgHover = '',
                                            onClickWhileDisabled
                                        }) {

    const useCustomColor = !!customBg && !!customBgHover;

    let buttonBgColor = `bg-blue-700`;
    let buttonHoverBgColor = `hover:bg-blue-900`;
    let textColor = `text-gray-800`;
    let cursor = 'cursor-pointer';

    if (useCustomColor) {
        buttonBgColor = `bg-${customBg}`;
        buttonHoverBgColor = `hover:bg-${customBgHover}`;
    }

    if (!!secondary) {
        buttonBgColor = `bg-coolGray-900`;
        buttonHoverBgColor = `hover:bg-coolGray-700`;
    }

    if (disabled) {
        buttonBgColor = `bg-gray-400`;
        buttonHoverBgColor = `hover:bg-gray-400`;
        cursor = 'cursor-not-allowed';
    }

    if (!!light) {
        textColor = `text-white`;
    }

    if (isLoading) {
        cursor = 'cursor-not-allowed';
    }

    const textSize = `text-${size}`;

    return (
        <CoreButton
            ref={!!ref ? ref : null}
            preventDefault={preventDefault}
            onClick={disabled ? !!onClickWhileDisabled ? onClickWhileDisabled : () => null : onClick}
            disabled={!!onClickWhileDisabled ? false : disabled}
            loadingColor={light ? 'white' : 'gray-800'}
            isLoading={isLoading}
            className={`transition duration-500 ease-in-out rounded-md drop-shadow-md filter px-6 py-3 ${textSize} ${cursor} ${buttonBgColor} ${buttonHoverBgColor} ${textColor}${!!className ?
                ` ${className}` :
                ''}`}>
            {children}
        </CoreButton>
    );

}
