import {calculateGrossPrice} from './productUtils';
import {PRODUCT_TYPE} from '../models/enum/productTypes';
import {calculateSubTotalNetFromLineItems} from './checkoutUtils';
import * as fbq from './fpixel';
import * as gtag from './gtag';
import * as fpixel from './fpixel';
import Router from 'next/router';

const _ = require('lodash');

export const emptyCart = () => {

    let cartData = localStorage.getItem('amp-cart');
    let cart = JSON.parse(cartData);
    cart.products = [];
    cart.lineItems = [];
    cart.configurationOptions = {};

    return cart;

};

export const createCart = (samplePrice) => {

    const {currencyCode, currency} = samplePrice;

    return {
        lineItems: [],
        configurationOptions: {},
        taxPercent: 19,
        currencyCode,
        currency,
    };

};

const getNextCartIndex = (lineItems) => {

    if (lineItems.length === 0) return 0;

    const lastItem = lineItems[lineItems.length - 1];
    const {cartIndex} = lastItem;

    return cartIndex + 1;

};

export const addSimpleProducts = (productList, qty) => {

    const [sampleProduct] = productList;
    const {price: samplePrice} = sampleProduct;

    const cartJSON = localStorage.getItem('amp-cart');

    let cart = !!cartJSON && cartJSON.length > 2 ?
        JSON.parse(cartJSON) :
        createCart(samplePrice);

    const {lineItems: originLineItems} = cart;

    for (let product of productList) {

        const {id: productId} = product;

        const productIsAlreadyInCart = !!originLineItems &&
            originLineItems.length > 0 &&
            originLineItems.findIndex(
                ({id}) => id === productId) >= 0;

        if (productIsAlreadyInCart) {

            const itemIndex = originLineItems.findIndex(
                ({id}) => id === productId);

            const _qty = originLineItems[itemIndex].qty + 1;
            const _lineItems = [...originLineItems];
            _lineItems[itemIndex].qty = _qty;
            cart = {...cart, lineItems: _lineItems};

        } else {
            const nextCartIndex = getNextCartIndex(originLineItems);

            const {inSale, salePrice, netPrice, tax: {taxPercent}} = product.price;

            const grossPrice = calculateGrossPrice(
                !!inSale ? salePrice : netPrice, taxPercent).toFixed(2);

            const newProduct = createNewProduct(product, grossPrice, 1);

            const lineItem = createLineItemSimpleProduct(product, nextCartIndex,
                qty);

            const lineItems = [lineItem];
            cart = addToCart(newProduct, lineItems, null, cart, PRODUCT_TYPE.SIMPLE_PRODUCT);
        }

    }

    return cart;
};

export const addMacroProduct = (productConfiguration) => {

    const {price} = productConfiguration;

    const cartJSON = localStorage.getItem('amp-cart');
    let cart = !!cartJSON && cartJSON.length > 2 ?
        JSON.parse(cartJSON) :
        createCart(price);

    const {lineItems: currentLineItems} = cart;

    const inSale = price.inSale;

    const grossPrice = calculateGrossPrice(
        inSale ?
            price.salePrice :
            price.netPrice,
        price.tax.taxPercent).toFixed(2);

    const newProduct = createNewMacroProduct(productConfiguration, grossPrice, 1);

    const nextCartIndex = getNextCartIndex(currentLineItems);

    const lineItems = getLineItems(productConfiguration, nextCartIndex);

    const configurationOptions = getConfigurationOptions(productConfiguration,
        nextCartIndex);

    return addToCart(newProduct, lineItems, configurationOptions, cart, PRODUCT_TYPE.MACRO_PRODUCT);

};

export const addNewConsultationProduct = (simpleProducts, settings, qty, selectedAnswerList) => {
    const [sampleProduct] = simpleProducts;
    const {price: samplePrice} = sampleProduct;

    const cartJSON = localStorage.getItem('amp-cart');

    let cart = !!cartJSON && cartJSON.length > 2 ?
        JSON.parse(cartJSON) :
        createCart(samplePrice);

    const {lineItems: originLineItems} = cart;

    for (let product of simpleProducts) {

        const {id: productId} = product;

        const productIsAlreadyInCart = !!originLineItems &&
            originLineItems.length > 0 &&
            originLineItems.findIndex(
                ({id}) => id === productId) >= 0;

        if (!productIsAlreadyInCart) {

            const nextCartIndex = getNextCartIndex(originLineItems);

            const {inSale, salePrice, netPrice, tax: {taxPercent}} = product.price;

            const grossPrice = calculateGrossPrice(
                !!inSale ? salePrice : netPrice, taxPercent).toFixed(2);

            const newProduct = createNewProduct(product, grossPrice, 1, PRODUCT_TYPE.CONSULTATION_PRODUCT);

            const lineItem = createLineItemSimpleProduct(product, nextCartIndex,
                qty, PRODUCT_TYPE.CONSULTATION_PRODUCT);

            const lineItems = [lineItem];

            const configurationOptions = getConfigurationOptionsFromSettings(settings,
                nextCartIndex, selectedAnswerList);

            cart = addToCart(newProduct, lineItems, configurationOptions, cart);
        }


    }

    return cart;

};

export const addToCart = (
    newProduct, lineItems, configurationOptions, cart) => {

    for (let lineItem of lineItems) {
        const {__typename} = lineItem;
        if (!__typename.toLowerCase().includes('installation')) {
            fbq.addToCart(lineItem);
            gtag.addToCart(lineItem);
        }
    }

    const _cart = {
        ...cart,
    };

    _cart.lineItems = _cart.lineItems.concat(lineItems);

    if (configurationOptions) {
        const [item] = lineItems;
        const {cartIndex} = item;

        _cart.configurationOptions[`CART_INDEX_${cartIndex}`] = configurationOptions;

    }

    return _cart;
};

/**
 * Create a new product object
 *
 * @param product
 * @param productPrice
 * @param qty
 * @param type
 * @returns {{image, productId: *, totalPrice: number, price, qty, name}}
 */
export const createNewProduct = (product, productPrice, qty, type = PRODUCT_TYPE.SIMPLE_PRODUCT) => {

    return {
        productId: product.id,
        image: product.simpleProductInformation.mainImage,
        name: product.title,
        price: productPrice,
        qty: qty,
        product: product,
        totalPrice: parseFloat((productPrice * qty).toFixed(2)),
        coupons: [],
        type,
    };
};
/**
 * Create a new product object
 *
 * @param productConfiguration
 * @param productPrice
 * @param qty
 * @returns {{image, productId: *, totalPrice: number, price, qty, name}}
 */
export const createNewMacroProduct = (
    productConfiguration, productPrice, qty) => {

    const {crossSellVariations, mainProduct, allQuestions} = productConfiguration;

    return {
        productId: mainProduct.microProduct.id,
        image: mainProduct.macroProduct.media.wireframe,
        name: mainProduct.macroProduct.title,
        price: productPrice,
        qty: qty,
        coupons: [],
        crossSellVariations,
        productConfiguration: {
            allQuestions,
            allAnswers: productConfiguration.allAnswers,
            bundleProduct: productConfiguration.bundleProduct,
            installationService: productConfiguration.installationService,
            mainProduct: productConfiguration.mainProduct,
            price: productConfiguration.price,
            stockInformation: productConfiguration.stockInformation,
        },
        totalPrice: parseFloat((productPrice * qty).toFixed(2)),
        type: PRODUCT_TYPE.MACRO_PRODUCT,
    };
};

export const getLineItems = (productConfiguration, index) => {

    const {
        allAnswers,
        allQuestions,
        bundleProduct,
        currentAnswer,
        currentQuestion,
        installationService,
        mainProduct,
        price,
        stockInformation,
    } = productConfiguration;

    const {microProduct} = mainProduct;
    const {
        hasBundleProduct,
        purchaseBundle,
        microProduct: microBundleProduct,
    } = bundleProduct;
    const {
        orderInstallation,
        installationService: installationServiceProduct,
    } = installationService;

    const firstLineItem = createLineItemMicroProduct(microProduct,
        productConfiguration.mainProduct, index);
    const lineItems = [firstLineItem];

    if (hasBundleProduct && purchaseBundle) {
        const lineItem = createLineItemMicroProduct(microBundleProduct,
            productConfiguration.bundleProduct,
            index);
        lineItems.push(lineItem);
    }

    if (orderInstallation)
        lineItems.push({...installationServiceProduct, cartIndex: index, qty: 1});

    return lineItems;
};

export const getConfigurationOptions = (
    productConfiguration, lastLineItemIndex) => {

    const {
        allAnswers,
        allQuestions,
    } = productConfiguration;

    return [{allQuestions, allAnswers, cartIndex: lastLineItemIndex}];

};
export const getConfigurationOptionsFromSettings = (
    settings, lastLineItemIndex, selectedAnswerList) => {

    const allQuestions = settings.map(({question}) => question);
    return [{allQuestions, allAnswers: selectedAnswerList, cartIndex: lastLineItemIndex}];

};

export const createOrderItems = (lineItems) => {

    const groupedLineItems = _.groupBy(lineItems, 'cartIndex');
    const _orderItems = [];
    let _cartIndex = 0;

    Object.entries(groupedLineItems).map(([cartIndex, lineItemList]) => {

        const [lineItem] = lineItemList;
        const {__typename} = lineItem;
        const isMicroProduct = __typename.toLowerCase().includes('micro');
        const isSimpleProduct = __typename.toLowerCase().includes('simple');

        if (isMicroProduct) {

            const _lineItems = lineItemList.map(item => {
                return {...item, cartIndex};
            });

            _orderItems.push(_lineItems);
            _cartIndex++;

        }

        if (isSimpleProduct) {
            for (let lineItem of lineItemList) {

                const _lineItem = {...lineItem, cartIndex};

                _orderItems.push([_lineItem]);
                _cartIndex++;

            }
        }

    });

    return _orderItems;

    // const {cartIndex: lastCartIndex} = lineItems[lineItems.length - 1];
    //
    // const orderItems = Array(lastCartIndex + 1);
    //
    // for (let lineItemIndex in lineItems) {
    //
    //   const lineItem = lineItems[lineItemIndex];
    //   lineItem.lineItemIndex = parseInt(lineItemIndex);
    //   const {cartIndex} = lineItem;
    //
    //   const orderItem = orderItems[cartIndex];
    //
    //   const isUndefined = orderItem === null ||
    //       checkIfUndefined(orderItem);
    //
    //   if (isUndefined) {
    //     orderItems[cartIndex] = [lineItem];
    //   } else {
    //     orderItems[cartIndex].push(lineItem);
    //   }
    //
    // }
    //
    // return orderItems;
};

function createLineItemSimpleProduct(product, lastLineItemIndex, qty, type = PRODUCT_TYPE.SIMPLE_PRODUCT) {

    const {
        id,
        price,
        shipping_types: shippingTypes,
        sku,
        stocks,
        title,
        simpleProductInformation,
        __typename,
    } = product;
    const {mainImage} = simpleProductInformation;

    const shortTitle = product.shortTitle !== null ?
        product.shortTitle :
        product.title;

    if (type === PRODUCT_TYPE.CONSULTATION_PRODUCT)
        return {
            id,
            price,
            __typename,
            shippingTypes,
            stocks,
            title,
            shortTitle,
            sku,
            thumbnail: mainImage,
            cartIndex: lastLineItemIndex,
            qty,
            type,
            ampPartner: product.factory.supplier
        };

    return {
        id,
        price,
        __typename,
        shippingTypes,
        stocks,
        title,
        shortTitle,
        sku,
        thumbnail: mainImage,
        cartIndex: lastLineItemIndex,
        qty,
        type
    };
}

function createLineItemMicroProduct(microProduct, mainProduct, index) {

    const {media, title} = mainProduct.macroProduct;
    const {wireframe} = media;

    return {
        ...microProduct,
        cartIndex: index,
        thumbnail: wireframe,
        shortTitle: title,
        qty: 1,
    };
}

export const calculateTotalPrice = (pricesList, taxPercent) => {

    const total = {
        totalPrice: 0,
        totalInSalePrice: 0,
        inSale: false,
        isFree: false,
        currency: '',
        currencyCode: '',
    };

    pricesList.map(({price}) => {

        const {netPrice, currency, currencyCode, inSale, isFree, salePrice} = price;

        if (isFree) {
            return total;
        }

        let finalPrice = !!inSale ? salePrice : netPrice;

        if (!!inSale)
            total.inSale = inSale;

        total.currency = currency;
        total.currencyCode = currencyCode;

        const grossPrice = calculateGrossPrice(netPrice, taxPercent);

        let inSalePrice = grossPrice;

        if (inSale) {

            inSalePrice = calculateGrossPrice(finalPrice, taxPercent);
            total.inSale = true;
        }

        total.totalPrice += grossPrice;
        total.totalInSalePrice += inSalePrice;

    });

    return total;
};

export const removeOrderItem = (orderItem) => {

    const [mainLineItem] = orderItem;
    const {cartIndex: toRemoveCartIndex} = mainLineItem;

    const cartData = localStorage.getItem('amp-cart');
    let cart = JSON.parse(cartData);

    cart.lineItems = cart.lineItems.filter(
        lineItem => lineItem.cartIndex !== parseInt(toRemoveCartIndex));

    delete cart.configurationOptions[`CART_INDEX_${toRemoveCartIndex}`];

    return cart;
};

export const updateQty = (changeCartIndex, qty) => {

    const cartData = localStorage.getItem('amp-cart');
    const cart = JSON.parse(cartData);

    const lineItemsIndizesToChange = _.compact(
        cart.lineItems.map((item, index) => {
            if (item.cartIndex === changeCartIndex)
                return index.toString();

            return null;
        }));

    for (const index of lineItemsIndizesToChange) {

        const _index = parseInt(index);
        cart.lineItems[_index].qty = qty;

    }

    return cart;

};

export const getCouponsToProduct = (coupons, lineItem) => {

    if (!!!coupons.microProducts || !!!coupons.simpleProducts)
        return [];

    const microProductCoupons = coupons.filter(coupon =>
        coupon.microProducts.filter(
            product => parseInt(product.id) ===
                parseInt(lineItem.data.id)).length > 0);

    const simpleProductCoupons = coupons.filter(coupon =>
        coupon.simpleProducts.filter(
            product => parseInt(product.id) ===
                parseInt(lineItem.data.id)).length > 0);

    return [...microProductCoupons, ...simpleProductCoupons];

};

export const calculateTotalDiscountFromLineItems = (lineItems) => {

    const discounts = [];
    for (let lineItem of lineItems) {
        discounts.push(calculateTotalDiscount(lineItem));
    }

    return _.sum(discounts);

};

export const calculateTotalDiscount = (lineItem) => {

    if (!!lineItem.__component && lineItem.__component.includes('woo'))
        return 0;

    if (!!!lineItem.coupons)
        return 0;

    const {coupons} = lineItem;

    const {qty} = lineItem;
    const {netPrice} = lineItem.data.price;

    return calculateDiscount(netPrice, qty, coupons);

};

export const calculateTotalDiscountFromLineItemsOnFrontend = (lineItems) => {

    const discounts = [];
    for (let lineItem of lineItems) {
        discounts.push(calculateTotalDiscountOnFrontend(lineItem));
    }

    return _.sum(discounts);
};

export const calculateTotalDiscountFromCoupons = (coupons, lineItems) => {

    if (!!!coupons)
        return 0;

    if (coupons.length === 0)
        return 0;

    let totalDiscount = 0;

    const productsOnlyLineItems = lineItems.filter(
        (item) => {
            const type = !!item.__typename ? item.__typename : item.__component;
            return !type.toLowerCase().includes('installation');
        });

    const subTotalNet = calculateSubTotalNetFromLineItems(productsOnlyLineItems);
    const absoluteDiscount = _.sum(
        coupons.map((coupon) => {

            const {type, amount, amountAbsolute} = coupon;

            if (type === 'ABSOLUTE')
                return amount;

            if (type === 'COMBINED')
                return amountAbsolute;

            return 0;

        }));

    const subtotalDiscountedWithAbsolute = subTotalNet - absoluteDiscount;

    const relativeDiscount = _.sum(coupons.map((coupon) => {
        const {type, amount, amountRelative} = coupon;

        if (type === 'RELATIVE')
            return subtotalDiscountedWithAbsolute * (amount / 100);

        if (type === 'COMBINED')
            return subtotalDiscountedWithAbsolute * (amountRelative / 100);

        return 0;


    }))

    totalDiscount += (absoluteDiscount + relativeDiscount);

    return totalDiscount;
};

export const calculateTotalDiscountOnFrontend = (lineItem) => {

    if (!!lineItem.__component && lineItem.__component.includes('woo'))
        return 0;

    if (!!!lineItem.coupons)
        return 0;

    const {coupons} = lineItem;

    const {qty} = lineItem;
    const {netPrice, inSale, salePrice} = lineItem.price;
    const price = !!inSale && !!salePrice ? salePrice : netPrice;

    return calculateDiscount(price, qty, coupons);

};

export const calculateDiscount = (price, qty, coupons) => {

    let totalDiscount = 0;
    const basePrice = price * qty;

    for (let coupon of coupons) {

        const {type, amountRelative, amount} = coupon;
        const isRelativeCoupon = type === 'RELATIVE';
        const isCombinedCoupon = type === 'COMBINED';

        if (isRelativeCoupon) {
            let discountPercentage = amount / 100;
            const discountedPrice = basePrice - totalDiscount;
            totalDiscount += discountedPrice * discountPercentage;
        }

        if (isCombinedCoupon) {
            let discountPercentage = amountRelative / 100;
            const discountedPrice = basePrice - totalDiscount;
            totalDiscount += discountedPrice * discountPercentage;
        }

    }
    return totalDiscount;

};

export const calculateTaxTotal = (
    subTotalNet, discountsNet, taxPercent, shippingNet) => {

    const taxFactor = taxPercent / 100;
    const productTax = (subTotalNet - discountsNet) * taxFactor;
    const shippingTax = shippingNet * (taxPercent / 100); //TODO: Set the tax for shipping dynamically

    return productTax + shippingTax;

};
export const calculateTaxTotalV2 = (
    subTotalNet, discountsNet, taxPercent, shippingNet) => {

    const taxFactor = taxPercent / 100;
    const productTax = (subTotalNet - discountsNet) * taxFactor;
    const shippingTax = shippingNet * (19 / 100); //TODO: Set the tax for shipping dynamically

    return productTax + shippingTax;

};

export const generateOrderNumbers = (
    subTotalNet, shippingNet_, feeNet, discountTotal_, refundedTotal, orderTax,
    shippingTax, feeTax, currency = '€', currencyCode = 'EUR') => {

    // Set discount to 0 if subTotalNet is 0
    const discountTotal = parseFloat(subTotalNet) <= 0 ? 0 : discountTotal_;
    const shippingNet = parseFloat(shippingNet_) <= 0 ? 0 : shippingNet_;

    const orderTotalNet = _.sum(
        [
            parseFloat(subTotalNet),
            parseFloat(shippingNet),
            parseFloat(feeNet),
            parseFloat(-1 * discountTotal)]);

    const feeTotal = calculateGrossPrice(feeNet, feeTax);
    const feeTaxTotal = feeTotal - feeNet;

    const shippingTotal = calculateGrossPrice(shippingNet, orderTax);

    const totalDiscountReduced = (subTotalNet - discountTotal).toFixed(2);

    let orderTotal =
        calculateGrossPrice(parseFloat(totalDiscountReduced), orderTax) +
        calculateGrossPrice(shippingNet, orderTax) +
        calculateGrossPrice(feeNet, feeTax);

    const taxTotal = orderTotal - orderTotalNet;

    return {
        orderTotal,
        orderTax,
        taxTotal,
        shippingNet,
        shippingTotal,
        shippingTax: 0,
        shippingTaxTotal: 0,
        feeNet,
        feeTotal,
        feeTax,
        feeTaxTotal,
        discountTotal,
        refundedTotal,
        currency,
        currencyCode,
        orderTotalNet,
        subTotalNet,
    };

};

export const calculateTotalGross = (
    subTotalNet, discountsNet, shippingNet, taxPercent, taxTotal) => {

    return (subTotalNet - discountsNet + parseFloat(shippingNet)) +
        taxTotal;

};

/***
 * @deprecated Please use generateUpdatedRefundOrderNumbers instead
 * @param lineItems
 * @param priceOverview
 * @param refundData
 * @returns {*}
 */
export const getUpdatedPriceOverview = (
    lineItems, priceOverview, refundData) => {

    const {discountsRefund, shippingRefund} = refundData;

    const updatedOverview = {
        ...priceOverview,
    };

    const {taxPercent, shipping} = priceOverview;

    const updatedDiscountsNet = priceOverview.discountsNet - discountsRefund;

    const subTotalNet = calculateSubTotalNetFromLineItems(
        lineItems.map(lineItem => {
            return {
                ...lineItem,
                price: lineItem.price,
            };
        }));
    const updatedShippingNet = shipping - shippingRefund;

    const taxTotal = calculateTaxTotal(subTotalNet, updatedDiscountsNet,
        taxPercent, updatedShippingNet);
    const totalGross = calculateTotalGross(subTotalNet, updatedDiscountsNet,
        updatedShippingNet,
        taxPercent, taxTotal);

    updatedOverview.discountsNet = Math.round(updatedDiscountsNet);
    updatedOverview.subTotalNet = Math.round(subTotalNet);
    updatedOverview.shipping = Math.round(updatedShippingNet);
    updatedOverview.taxTotal = Math.round(taxTotal);
    updatedOverview.totalGross = Math.round(totalGross);

    return updatedOverview;
};

export const generateUpdatedRefundOrderNumbers = (
    lineItems, numbers, refundData) => {

    const {discountsRefund, shippingRefund} = refundData;

    const {
        orderTax,
        shippingTotal,
        discountTotal,
        feeNet,
        refundedTotal,
        shippingTax,
        feeTax,
    } = numbers;

    const updatedDiscountTotal = discountTotal - discountsRefund;

    const subTotalNet = calculateSubTotalNetFromLineItems(
        lineItems.map(lineItem => {
            return {
                ...lineItem,
                price: lineItem.price,
            };
        }));

    const updatedShippingNet = shippingTotal - shippingRefund;

    //TODO: The refunded total should be different now
    return generateOrderNumbers(subTotalNet, updatedShippingNet, feeNet,
        updatedDiscountTotal, refundedTotal, orderTax, shippingTax, feeTax);
    //
    // const taxTotal = calculateTaxTotal(subTotalNet, updatedDiscountTotal,
    //     orderTax, updatedShippingNet);
    // const totalGross = calculateTotalGross(subTotalNet, updatedDiscountTotal,
    //     updatedShippingNet,
    //     orderTax, taxTotal);
    //
    // updatedOverview.discountsNet = Math.round(updatedDiscountTotal);
    // updatedOverview.subTotalNet = Math.round(subTotalNet);
    // updatedOverview.shipping = Math.round(updatedShippingNet);
    // updatedOverview.taxTotal = Math.round(taxTotal);
    // updatedOverview.totalGross = Math.round(totalGross);
    //
    // return updatedOverview;
};

export const applyDiscountToCart = (
    microIdList, simpleIdList, coupon, cart) => {

    const {lineItems} = cart;
    const {id: couponId} = coupon;

    const _lineItems = lineItems.map(item => {

        const {id: productId, __typename, price} = item;

        const isMicroProduct = __typename.toLowerCase().includes('micro');
        const isSimpleProduct = __typename.toLowerCase().includes('simple');

        let couponIsApplicable = false;

        if (isMicroProduct) {
            couponIsApplicable = microIdList.findIndex(
                id => id === parseInt(productId)) >= 0;
        }

        if (isSimpleProduct) {
            couponIsApplicable = simpleIdList.findIndex(
                id => id === parseInt(productId)) >= 0;
        }

        if (!couponIsApplicable)
            return item;

        const couponList = !!item.coupons ? item.coupons : [];

        const couponIsAlreadyInList = couponList.findIndex(
            ({id}) => parseInt(id) === couponId) >= 0;

        if (!couponIsAlreadyInList)
            couponList.push(coupon);

        return {...item, coupons: couponList};

    });

    return {...cart, lineItems: _lineItems};

};

export const removeDiscountFromCart = (coupon, cart) => {

    const {lineItems} = cart;
    const {id: couponId} = coupon;

    const _lineItems = lineItems.map(item => {

        const {coupons} = item;

        if (!!!coupons)
            return item;

        const couponIsActive = coupons.findIndex(
            ({id}) => parseInt(id) === parseInt(couponId)) >= 0;

        if (!couponIsActive)
            return item;

        const _coupons = coupons.filter(
            ({id}) => parseInt(id) !== parseInt(couponId));

        return {...item, coupons: _coupons};

    });

    return {...cart, lineItems: _lineItems};

};
export const prefix = {
    invoiceAddress: 'invoiceAddress',
    shippingAddress: 'shippingAddress',
    vehicleInformation: 'vehicleInformation',
};
export const formKeys = {
    CUSTOM_SHIPPING_ADDRESS: 'customShippingAddress',
    EMAIL: 'email',
    INVOICE_ADDRESS_CITY: prefix.invoiceAddress + '.city',
    INVOICE_ADDRESS_COMPANY: prefix.invoiceAddress + '.companyName',
    INVOICE_ADDRESS_COUNTRY: prefix.invoiceAddress + '.country',
    INVOICE_ADDRESS_FORENAME: prefix.invoiceAddress + '.foreName',
    INVOICE_ADDRESS_LASTNAME: prefix.invoiceAddress + '.lastName',
    INVOICE_ADDRESS_POSTAL_CODE: prefix.invoiceAddress + '.postalCode',
    INVOICE_ADDRESS_STREET: prefix.invoiceAddress + '.street',
    MOBILE_PHONE: 'telephoneNumber',
    ORDER_NOTES: 'orderNotes',
    SHIPPING_ADDRESS_CITY: prefix.shippingAddress + '.city',
    SHIPPING_ADDRESS_COMPANY: prefix.shippingAddress + '.companyName',
    SHIPPING_ADDRESS_COUNTRY: prefix.shippingAddress + '.country',
    SHIPPING_ADDRESS_FORENAME: prefix.shippingAddress + '.foreName',
    SHIPPING_ADDRESS_LASTNAME: prefix.shippingAddress + '.lastName',
    SHIPPING_ADDRESS_POSTAL_CODE: prefix.shippingAddress + '.postalCode',
    SHIPPING_ADDRESS_STREET: prefix.shippingAddress + '.street',
    MILEAGE: prefix.vehicleInformation + '.mileage',
    VIN: prefix.vehicleInformation + '.vin',
    PLATE_NUMBER: prefix.vehicleInformation + '.plateNumber',
    SHIPPING: 'shipping',
    INSTALLATION_WORKSHOP_ID: 'installationWorkshopId',
    PAYMENT_METHOD: 'paymentMethod',
    ORDER_CONTROLS: 'orderControls',
    SELECTED_WORKSHOP: 'shippingType.selectedWorkshop',
};

export const onCheckoutClick = async (cart) => {
    fpixel.initiateCheckout();
    gtag.initiateCheckout(cart);

    await Router.replace(`/checkout`);
};

export const findAllShippingTypesFromLineItems = (lineItems) => {

    return _.flattenDeep(lineItems.map((lineItem) => {

        const {__typename} = lineItem;

        if (__typename.toLowerCase().includes('micro')) {
            return lineItem.shipping_types; //TODO Refactor to shippingTypes
        }

        if (__typename.toLowerCase().includes('simple')) {
            return lineItem.shippingTypes;
        }

        return [];

    }));

};

export const checkIfCartIsEmpty = (cart) => {

    if (!cart)
        return true;

    const {lineItems} = cart;

    return lineItems.length === 0;

};
