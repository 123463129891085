import {getMarketingCookieAllowed} from './cookieUtils';
import {calculateGrossPrice, calculatePrice} from './productUtils';
import _ from 'lodash';
import * as Sentry from '@sentry/nextjs';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
const environment = process.env.NODE_ENV;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {

  if (environment === 'development')
    return;

  try {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  } catch (e) {
    // Silent
    Sentry.captureException(new Error(e));
  }
};

export const gtagCookiesAccepted = (url) => {

  if (environment === 'development')
    return;

  try {

    window.gtag('event', 'conversion', {
      'send_to': 'AW-619002068/S5srCP2py84DENTxlKcC'
    });

  } catch (e) {
    // Silent
    Sentry.captureException(new Error(e));
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({action, category, label, value}) => {

  if (environment === 'development')
    return;

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const addToCart = (lineItem) => {

  if (environment === 'development')
    return;

  try {
    // if (getMarketingCookieAllowed()) {

    // Micro product
    const {__typename, id, title, qty} = lineItem;
    const {netPrice} = lineItem.price;
    const {taxPercent} = lineItem.price.tax;

    const grossPrice = calculateGrossPrice(netPrice, taxPercent);

    window.gtag('event', 'conversion', {
      'send_to': 'AW-619002068/snhzCJSe0qEDENTxlKcC',
      'value': parseFloat(grossPrice),
      'currency': 'EUR',
    });

    const addToCartItems = [lineItem].map(item => {

      const {title, qty, sku} = item;
      const {netPrice} = lineItem.price;
      const {taxPercent} = lineItem.price.tax;

      const grossPrice = calculateGrossPrice(netPrice, taxPercent);

      return {
        'id': sku,
        'name': title,
        'quantity': qty,
        'price': grossPrice,
      };
    });
    window.gtag('event', 'add_to_cart', {
      'items': addToCartItems,
    });

    // }
  } catch (e) {
    // Silent
    Sentry.captureException(new Error(e));
  }
};

export const initiateCheckout = (cart) => {

  if (environment === 'development')
    return;

  try {
    // if (getMarketingCookieAllowed()) {

    const {lineItems} = cart;
    const items = lineItems.filter(
        item => !item.__typename.includes('installation')).map(item => {

      const {id} = item;
      const {netPrice} = item.price;
      const {taxPercent} = item.price.tax;

      const grossPrice = calculateGrossPrice(netPrice, taxPercent);

      return {
        id,
        name,
        quantity: 1,
        price: parseFloat(grossPrice),

      };

    });

    const totalPrice = _.sum(items.map(({price}) => price));

    window.gtag('event', 'conversion', {
      'send_to': 'AW-619002068/DHuRCLKCoaIDENTxlKcC',
      'value': totalPrice,
      'currency': 'EUR',
    });

    const checkoutItems = lineItems.filter(
        item => !item.__typename.includes('installation')).map(item => {

      const {id, sku, title, qty} = item;
      const {netPrice} = item.price;
      const {taxPercent} = item.price.tax;

      const grossPrice = calculateGrossPrice(netPrice, taxPercent);

      return {
        'id': sku,
        'name': title,
        'quantity': qty,
        'price': grossPrice,

      };

    });
    console.log(checkoutItems);
    window.gtag('event', 'begin_checkout', {
      'items': checkoutItems,
    });

    // }
  } catch (e) {
    // Silent
    Sentry.captureException(new Error(e));
  }
};

export const trackGooglePurchase = (order) => {

  if (environment === 'development')
    return;

  try {
    // if (getMarketingCookieAllowed()) {
    const {
      created_at,
      locale,
      lineItems,
      orderNumber,
      priceOverview,
      shippingAddress,
      invoiceAddress,
      paymentMethod,
      shippingType,
      numbers,
    } = order;

    const {
      shippingTotal,
      taxTotal,
      orderTotal,
      currencyCode,
    } = numbers;

    window.gtag('event', 'conversion', {
      'send_to': 'AW-619002068/n0hACL2px6EDENTxlKcC',
      'value': parseFloat(orderTotal),
      'currency': 'EUR',
      'transaction_id': orderNumber,
    });

    const purchaseItems = order.lineItems.filter(
        item => !item.__typename.includes('installation')).map(item => {

      const {sku, title, qty} = item;

      const {netPrice} = item.price;
      const {taxPercent} = item.price.tax;
      const grossPrice = calculateGrossPrice(netPrice, taxPercent);

      return {
        'id': sku,
        'name': title,
        'quantity': qty,
        'price': grossPrice,
      };

    });

    window.gtag('event', 'purchase', {
      'transaction_id': orderNumber,
      'value': parseFloat(orderTotal),
      'currency': 'EUR',
      'tax': taxTotal,
      'shipping': shippingTotal,
      'items': purchaseItems,
    });

    // }
  } catch (e) {
    // Silent
    Sentry.captureException(new Error(e));
  }
};

export const trackProductImpression = (productList) => {

  if (environment === 'development')
    return;

  const items = productList.map(product => {

    const {
      sku,
      title,
      price,
    } = product;

    const {grossPrice} = calculatePrice(price);
    return {
      'id': sku,
      'name': title,
      'quantity': 1,
      'price': grossPrice,
    };
  });
  try {

    window.gtag('event', 'view_item_list', {
      'items': items,
    });

  } catch (e) {
    // Silent
    console.error(e);
    Sentry.captureException(new Error(e));
  }
};

export const trackProductDetailViews = (productList) => {

  if (environment === 'development')
    return;

  const items = productList.map(product => {

    const {
      sku,
      title,
      price,
    } = product;

    const {grossPrice} = calculatePrice(price);
    return {
      'id': sku,
      'name': title,
      'quantity': 1,
      'price': grossPrice,
    };
  });
  try {

    window.gtag('event', 'view_item', {
      'items': items,
    });

  } catch (e) {
    // Silent
    console.error(e);
    Sentry.captureException(new Error(e));
  }
};
