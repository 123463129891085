import {useState} from 'react';
import {getDescendantProp} from '../../lib/utils/generalUtils';

export default function InputForm({
                                    className = '',
                                    inputClassName,
                                    errors,
                                    inputKey,
                                    register,
                                    isRequired,
                                    placeholder,
                                    disabled,
                                    type,
                                    showPlaceholderAsLabel = false,
                                    watch,
                                    useMin,
                                    min,
                                    useMax,
                                    max,
                                    height = 'h-12',
                                    floatingSteps,
                                    autoComplete = 'on',
                                    onChange,
                                    value,
                                    useReactForm = true,
                                    description = '',
                                  }) {
  let inputValue;
  let error;
  if (useReactForm) {
    inputValue = watch(inputKey);
    error = getDescendantProp(errors, inputKey);
  } else {
    inputValue = value;
  }

  const [showPassword, setShowPassword] = useState(false);
  const [onFocused, setOnFocused] = useState(false);
  const typeIsPassword = type === 'password';
  const hasInput = !!inputValue ||
      (typeof inputValue === 'number' && inputValue.toString().length > 0);

  const toggleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const onFocus = () => {
    setOnFocused(true);
  };
  const onBlur = () => {
    setOnFocused(false);
  };

  return <div className={`relative ${className}`}>
    {!!error && <label
        className={'absolute text-tiny left-2 top-0.5 text-red-500 font-semibold'}>{error.message}</label>}


    {useReactForm ? <input
        {...register(inputKey, {
          required: isRequired,
          onChange: !!onChange ? (e) => onChange(e) : null,
        })}
        onFocus={onFocus} onBlur={onBlur}
        id={inputKey}
        disabled={disabled}
        autoComplete={autoComplete}
        className={`${inputClassName} appearance-none block rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-700 focus:border-4 border ${height} transition text-sm ${!!error ?
            'border-red-500' :
            'border-gray-200'}`}
        type={typeIsPassword ?
            showPassword ? 'text' : 'password' :
            type}
        // placeholder={onFocused ? '' : placeholder}
        min={!!useMin ? min : null}
        max={!!useMax ? max : null}
        step={floatingSteps ? '0.01' : '1'}
    /> : <input
        onChange={!!onChange ? (e) => onChange(e) : null}
        value={inputValue}
        onFocus={onFocus} onBlur={onBlur}
        id={inputKey}
        disabled={disabled}
        autoComplete={autoComplete}
        className={`${inputClassName} appearance-none block rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-700 focus:border-4 border ${height} transition text-sm ${!!error ?
            'border-red-500' :
            'border-gray-200'}`}
        type={typeIsPassword ?
            showPassword ? 'text' : 'password' :
            type}
        // placeholder={onFocused ? '' : placeholder}
        min={!!useMin ? min : null}
        max={!!useMax ? max : null}
        step={floatingSteps ? '0.01' : '1'}
    />}

    {!!description && <p className={'text-tiny text-gray-800 mt-2'}> {description}</p>}


    {!!useReactForm && !errors[inputKey] && showPlaceholderAsLabel && <label
        className={`absolute top-3 left-3.5 -z-1 text-gray-500 duration-300 origin-0 pointer-events-none ${onFocused ||
        hasInput ?
            'text-tiny transform -translate-x-1 -translate-y-2.5' :
            ''}`}>{placeholder}</label>}
    {!useReactForm && showPlaceholderAsLabel && <label
        className={`absolute top-3 left-3.5 -z-1 text-gray-500 duration-300 origin-0 pointer-events-none ${onFocused ||
        hasInput ?
            'text-tiny transform -translate-x-1 -translate-y-2.5' :
            ''}`}>{placeholder}</label>}

    {typeIsPassword &&
    <label
        htmlFor={inputKey}
        className={'absolute transition-all right-3 top-3.5 text-gray-500 text-gray-700 w-5 cursor-pointer'}
        onClick={(e) => toggleShowPassword(e)}>
      {showPassword ?
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                  clipRule="evenodd"/>
            <path
                d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"/>
          </svg> :
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"/>
            <path fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"/>
          </svg>}

    </label>}
  </div>;

}
