import React, {useState, useEffect} from 'react';
import Router from 'next/router';
import {checkUserAsync} from '../../lib/api/rest/users';
import {useLocalStorage} from '../../lib/hooks/useLocalStorage';
import {checkIfUserHasToken} from '../../lib/utils/routeUtils';
import {findOneAsync} from '../../lib/api/rest/strapi';
import {paths} from '../../lib/api/rest/paths';
import Cookie from 'js-cookie';

export const CartContext = React.createContext(
    [
      {}, () => {
    }],
);

export const UserContext = React.createContext(
    [
      {}, () => {
    }],
);
export const UIContext = React.createContext(
    [
      {}, () => {
    }],
);

export const SearchContext = React.createContext(
    [
      {}, () => {
    }],
);
export const ModalContext = React.createContext(
    [
      {}, () => {
    }],
);

/**
 * Provides a global application context for the entire application with the cart contents
 * @param {Object} props
 */
export const AppProvider = ({children}) => {

  const [cart, setCart] = useLocalStorage('amp-cart', null);
  const [user, setUser] = useLocalStorage('user', {
    data: null,
    isAuthenticated: true,
  });

  const [modal, setModal] = useLocalStorage('modal', {
    showNewsletterModal: true,
  });

  const [ui, setUi] = useState({
    cartIsOpen: false,
    sideMenuIsOpen: false,
    stickyFooterIsOpen: true,
    newsletterModalIsOpen: false,
  });

  const [search, setSearch] = useState({
    list: [],
  });

  async function syncLogout(event) {

    if (event.key === 'logout') {
      setUser({data: null, isAuthenticated: false});
      await Router.push('/login');
    }

  }

  useEffect(() => {

    // Check if we are client-side before we access the localStorage
    if (process.browser && !!navigator.cookieEnabled) {
      let cartData = localStorage.getItem('amp-cart');
      cartData = null !== cartData ?
          JSON.parse(cartData) :
          '';
      setCart(cartData);

      window.addEventListener('storage', syncLogout);
      return () => {
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem('logout');
      };
    }
  }, []);

  useEffect(() => {

    const userHasToken = checkIfUserHasToken();

    if (userHasToken) {

      checkUserAsync().then(async response => {

        const {ampPartner: ampPartnerId} = response;
        const data = response;

        if (!!ampPartnerId) {
          findOneAsync(paths.AMP_PARTNER, ampPartnerId, true).
              then(ampPartnerResponse => {
                delete ampPartnerResponse['orders'];
                data['ampPartner'] = ampPartnerResponse;
              });
        }

        const _user = {
          data,
          isAuthenticated: true,
        };

        setUser(_user);

      }).catch(async err => {

        const _user = {
          data: null,
          isAuthenticated: false,
        };

        setUser(_user);

        Cookie.remove('token');
        await Router.replace('/login');
      });

    } else {

      const _user = {
        data: null,
        isAuthenticated: false,
      };

      setUser(_user);
    }

  }, []);

  return (
      <CartContext.Provider value={[cart, setCart]}>
        <UserContext.Provider value={[user, setUser]}>
          <UIContext.Provider value={[ui, setUi]}>
            <ModalContext.Provider value={[modal, setModal]}>
              <SearchContext.Provider value={[search, setSearch]}>
                {children}
              </SearchContext.Provider>
            </ModalContext.Provider>
          </UIContext.Provider>
        </UserContext.Provider>
      </CartContext.Provider>
  );
};
