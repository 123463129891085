export const calculatePriceWithDiscount = (price, discount) => {

  const {
    isRelativeDiscount,
    isAbsoluteDiscount,
    absoluteDiscount,
    relativeDiscount,
  } = discount;

  let finalPrice = price;

  if (isAbsoluteDiscount)
    finalPrice -= absoluteDiscount;

  if (isRelativeDiscount)
    finalPrice -= (price * (relativeDiscount / 100));

  return finalPrice;

};

export const parseToPriceString = (
    amount, locales = 'de-DE', currency = 'EUR') => {
  return new Intl.NumberFormat(locales,
      {style: 'currency', currency}).format(
      amount);
};