import React, {useContext, useEffect, useRef} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

import {checkIfUndefined} from '../../lib/utils/generalUtils';
import {mainRoutes, secondaryRoutes} from '../../lib/routes';
import {logout} from '../../lib/api/rest/auth';
import {closeSideBar, toggleSideBar} from '../../lib/utils/uiUtils';
import {UIContext, UserContext} from '../../utils/context/appContext';
import LanguageMenu from './languageMenu';
import {animated, useTransition} from '@react-spring/web';

function MainSidebar({
                       sidebarOpen,
                       userData,
                       ui, setUi,
                     }) {

  const router = useRouter();
  const [, setUser] = useContext(UserContext);
  const {t: translateMenu} = useTranslation('menu');

  const transitions = useTransition(sidebarOpen, {
    from: {
      x: 1000,
      opacity: 0,
    },
    enter: {
      x: 0,
      opacity: 1,
    },
    leave: {
      x: 1000,
      opacity: 0,
    },
    config: {duration: 100},

  });

  const {pathname, locale} = router;
  const page = checkIfUndefined(pathname.split('/')[2]) ?
      '' :
      pathname.split('/')[2];

  return (
      <div className="">
        {/* Sidebar backdrop (mobile only) */}
        <div
            className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 hidden z-auto transition-opacity duration-200 ${sidebarOpen ?
                'opacity-100' :
                'opacity-0 pointer-events-none'}`} aria-hidden="true"/>

        {/* Sidebar */}
        {
          transitions((style, show) =>

              show ? <animated.div id={'sidebar'}
                                   className={`absolute z-40 right-0 top-0 transform h-screen overflow-y-scroll no-scrollbar w-64 md:w-96 flex-shrink-0 bg-black p-4 transition-all duration-300 ${sidebarOpen ?
                                       '' :
                                       'z-auto'}`}
                                   style={{
                                     ...style,
                                   }}
              >


                {/* Sidebar header */}
                <div className="flex justify-between mb-2 pr-3 sm:px-2">
                  {/* Close button */}
                  <button
                      className="text-gray-500 hover:text-gray-400 mt-10"
                      onClick={(e) => toggleSideBar(e, ui, setUi)}
                      aria-controls="sidebar"
                      aria-expanded={sidebarOpen}
                  >
                    <span className="sr-only">Close sidebar</span>

                    <div className={'w-4'} onClick={(e) => {

                      e.preventDefault();

                      closeSideBar(ui, setUi);
                    }}>
                      <svg className="w-6 h-6" fill="none" stroke="currentColor"
                           viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                      </svg>
                    </div>
                  </button>
                </div>
                <ul>
                  {mainRoutes(translateMenu, router).map((link) =>
                      <li className={'uppercase text-sm border-b border-gray-700 py-5 text-gray-100 hover:text-white transition'}
                          key={link.title}>
                        <Link href={link.uri}>
                          <a onClick={() => {
                            closeSideBar(ui, setUi);
                          }}>
                            <span className={'px-3 '}>{link.title} </span>
                          </a>
                        </Link>

                      </li>,
                  )}

                  {secondaryRoutes(translateMenu, locale).map((link) =>
                      <li className={'uppercase text-sm border-b border-gray-700 py-5 text-gray-100 hover:text-white transition'}
                          key={link.title}>
                        <Link href={link.uri}>
                          <a onClick={(e) => {
                            toggleSideBar(e, ui, setUi);
                          }}>
                            <span className={'px-3 '}>{link.title} </span>
                          </a>
                        </Link>
                      </li>,
                  )}

                  {!!userData ?
                      <li className={'uppercase text-sm py-5 text-gray-100 hover:text-white transition cursor-pointer'}
                          key={'link-logout'} onClick={async (e) => {
                        e.preventDefault();

                        await logout('/', setUser);
                      }
                      }>

                          <span className={'px-3 '}> {translateMenu(
                              'LOGOUT')} </span>


                      </li> :
                      <li className={'uppercase text-sm py-5 text-gray-100 hover:text-white transition cursor-pointer'}
                          key={'link-logout'} onClick={async (e) => {

                        toggleSideBar(e, ui, setUi);
                        await router.push('/login');

                      }
                      }>

                          <span className={'px-3 '}>{translateMenu(
                              'LOGIN')} </span>
                      </li>}
                </ul>
                <LanguageMenu className={'md:hidden mr-5'} router={router}/>
              </animated.div> : '',
          )
        }
      </div>
  );
}

export default MainSidebar;