export const SHIPPING_TYPE = {
  SHIP_BY_COURIER: 'SHIP_BY_COURIER',
  INSTALLATION: 'INSTALLATION',
  RANGER: 'RANGER',
  PICK_UP: 'PICK_UP',
};

export function parseShippingType(shippingType) {

  switch (shippingType) {

    case SHIPPING_TYPE.SHIP_BY_COURIER:
      return SHIPPING_TYPE.SHIP_BY_COURIER;

    case SHIPPING_TYPE.INSTALLATION:
      return SHIPPING_TYPE.INSTALLATION;

    case SHIPPING_TYPE.RANGER:
      return SHIPPING_TYPE.RANGER;

    case SHIPPING_TYPE.PICK_UP:
      return SHIPPING_TYPE.PICK_UP;

  }

}