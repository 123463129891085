import remark from 'remark';
import html from 'remark-html';
import {parseQuestionType, QUESTION_TYPE} from '../models/enum/questionType';
import {parseToPriceString} from './priceUtils';
import _ from 'lodash';
import {getFinalStockInformation} from './selectorUtils';
import Router from 'next/router';

/**
 *
 * Get shipping information of a Micro Product or Simple Product
 *
 * @param stocks
 * @returns {boolean|{shippingTime: {minDay: number, maxDay: number}, productIsAvailable: boolean}}
 */
export const calculateShippingInformation = (stocks) => {

    if (!Array.isArray(stocks) || stocks.length <= 0) return null;

    let shippingInformation = {
        productIsAvailable: false,
        stockId: null,
        shippingTime: {
            minDay: 300,
            maxDay: 0,
            friendlyText: '',
        },
        totalStock: 0,
    };

    //TODO: Change shipping information to secondary stock location if the first one is not available

    let primaryStockInformation = findPrimaryStockLocation(stocks);

    const {allowBackorder, qty} = primaryStockInformation;

    const productIsAvailable = qty > 0 ||
        allowBackorder;
    shippingInformation.totalStock = qty;

    shippingInformation.productIsAvailable = productIsAvailable;
    shippingInformation.shippingTime = primaryStockInformation.shippingTime;
    shippingInformation.stockId = primaryStockInformation.id;

    return shippingInformation;

};

export const findPrimaryStockLocation = (stocks, defaultShippingTime = {
    minDay: 14,
    maxDay: 120,
    friendlyText: "Versandzeit unbekannt"
}) => {

    let primaryStock = stocks.find(({primary}) => primary);

    if (primaryStock && primaryStock.length > 0)
        primaryStock = stocks[0];

    if (!primaryStock) {
        primaryStock = {
            qty: 0,
            allowBackorder: true,
            shippingTime: defaultShippingTime
        }
    }

    const {allowBackorder, qty} = primaryStock;

    if (qty > 0)
        return primaryStock;

    if (allowBackorder)
        return primaryStock;

    let backorderStock = stocks.find(
        ({allowBackorder}) => allowBackorder);

    if (!!!backorderStock)
        backorderStock = primaryStock;

    return backorderStock;

};

export const calculateVariationProductTotalPrice = (setting) => {

    const {
        discount,
        simpleProducts,
    } = setting;

    let _simpleProducts = [...simpleProducts];

    if (!!discount) {

        const {simpleProducts: discountedSimpleProducts} = discount;

        _simpleProducts = _simpleProducts.map((product) => {

            const {id: productId} = product;

            const isDiscounted = discountedSimpleProducts.map(({id}) => parseInt(id)).includes(parseInt(productId));

            if (isDiscounted && !!!product.price.salePriceIsCalculated) {

                const {type, amount} = discount;

                const {inSale, netPrice, salePrice} = product.price;

                if (type === 'ABSOLUTE') {

                    product.price.salePrice = inSale ?
                        salePrice - amount :
                        netPrice - amount;

                }

                if (type === 'RELATIVE') {

                    product.price.salePrice = inSale ?
                        salePrice - (salePrice * amount / 100) :
                        netPrice - (netPrice * amount / 100);

                }

                product.price.inSale = true;
                product.price['salePriceIsCalculated'] = true;
            }

            return product;

        });
    }

    const pricesArray = _simpleProducts.map(({price}) => price);
    const totalNetPrice = _.sum(pricesArray.map(({netPrice}) => netPrice));
    const totalSalePrice = _.sum(pricesArray.map(
        ({salePrice, netPrice, inSale}) => !!inSale ? salePrice : netPrice));
    const isInSale = pricesArray.map(({inSale}) => !!inSale).includes(true);

    const [simpleProduct] = _simpleProducts;
    const {price} = simpleProduct;

    return {
        ...price,
        netPrice: totalNetPrice,
        salePrice: totalSalePrice,
        inSale: isInSale,
    };

};

export const calculateVariationStocks = (setting) => {

    const {
        simpleProducts,
    } = setting;

    return getFinalStockInformation(simpleProducts.map(
        ({stocks}) => calculateShippingInformation(stocks)));

};

/**
 *
 * Get price string
 *
 * @param price
 * @param taxInPercent
 * @param currency
 * @param currencyOnRight
 * @returns {string}
 */
export const getGrossPriceString = (
    price, taxInPercent = 19) => {
    const grossPrice = calculateGrossPrice(price, taxInPercent).toFixed(2);

    return parseToPriceString(grossPrice);
};

/**
 * Calculate gross price
 *
 * @param netPrice
 * @param taxInPercent
 * @returns {number}
 */

export const calculateGrossPrice = (netPrice, taxInPercent) => {
    if (netPrice === null) console.error('Net price is null');
    if (taxInPercent === null) console.error('Tax is null');

    const total = (netPrice * (1 + (taxInPercent / 100)));
    return Math.round(total * 100) / 100;

};

export const calculatePrice = (price) => {

    const {netPrice, inSale, salePrice, tax: {taxPercent}} = price;

    const _netPrice = !!inSale && !!salePrice ?
        salePrice :
        netPrice;

    return {
        netPrice: _netPrice,
        grossPrice: calculateGrossPrice(_netPrice, taxPercent),
    };
};

export const calculateOrderTotal = (
    productsSubTotal, shippingTotal, feeTotal) => {

    return productsSubTotal + shippingTotal + feeTotal;

};

/**
 * Return if product is currently in sale
 *
 * @param product
 * @returns {false|*}
 */
export const isProductInSale = (product) => {
    return product.price.inSale != null && product.price.inSale;
};

/**
 * Return product id of the bundled product
 *
 * @param data
 * @returns {Promise<null|*>}
 */
export async function getBundleProduct(data) {

    let bundleProductId;
    const extra = data.macroProducts[0].extra;
    if (extra.isEmpty) return null;

    extra.map(async extraItem => {

        const isFromTypeQuestion = extraItem.__typename ===
            'ComponentComplexProductQuestion';
        const isBundleQuestion = extraItem.type === 'BUNDLE';

        if (isFromTypeQuestion && isBundleQuestion) {

            extraItem.Answer.map(async answer => {

                const agreeToInstallation = answer.overrideInstallationCost;

                if (agreeToInstallation) {

                    bundleProductId = parseInt(answer.macroProduct.id);

                }

            });

        }

    });

    return bundleProductId;

}

/**
 *
 * Return markdown content from category page
 *
 * @param data
 * @returns {Promise<{scopeOfDeliveryHtml: string, productDescription: *[]}>}
 */

export async function parseMarkdownFromCategoryPage(data) {

    const macroProduct = data.macroProducts[0];

    const productDescriptions = [];
    macroProduct.productDescription.map(async productDescription => {

            const markdown = await remark().use(html).process(productDescription.content);
            productDescriptions.push(markdown.toString());

        },
    );

    const scopeOfDeliveryHtml = await remark().use(html).process(macroProduct.scopeOfDelivery);

    return {
        productDescription: productDescriptions,
        scopeOfDeliveryHtml: scopeOfDeliveryHtml.toString(),
    };
}

/**
 * Return a list of questions from a macro product
 *
 * @param macroProduct
 * @param productQuestionType
 * @returns {{productQuestions: *[], bundledQuestion: *[], installationQuestion: *[]}}
 */
export const initQuestions = (macroProduct, productQuestionType) => {

    let questions = {
        'productQuestions': [],
        'installationQuestion': [],
        'bundledQuestion': [],
    };

    macroProduct.extra.map((extraItem, index) => {
        const isQuestionType = extraItem.__typename.toLowerCase().includes('productquestion');

        if (isQuestionType) {

            const questionType = parseQuestionType(extraItem.type);

            let defaultItem = {
                ...extraItem,
                active: true,
                title: macroProduct.title,
                id: `${macroProduct.id}-${index}`,
                type: questionType,
                productQuestionType: productQuestionType,
            };
            defaultItem.Answer.map(answer => {

                return {...answer, checked: false};

            });
            switch (questionType) {

                case QUESTION_TYPE.LABEL:
                case QUESTION_TYPE.COLOR:
                    questions.productQuestions.push(defaultItem);
                    break;
                case QUESTION_TYPE.BUNDLE:
                    questions.bundledQuestion.push(defaultItem);
                    break;

                case QUESTION_TYPE.INSTALLATION:
                    questions.installationQuestion.push(defaultItem);
                    break;

            }

        }

    });

    return questions;
};

export function initAnswers() {
    return {
        'productQuestions': null,
        'installationQuestion': null,
        'bundledQuestion': null,
    };
}

export const getAllMicroProductsFromMacro = (macroProduct) => {

    const allMicroProducts = [];

    macroProduct.microProducts.map(
        microProduct => allMicroProducts.push(microProduct));

    return allMicroProducts;
};

export const findLowestProductPrice = (productList, productType = 'macro') => {

    let lowestUpsellPrices = Array(productList.length).fill(9999999);
    let currency = null;

    for (const index in productList) {

        const product = productList[index];
        if (productType === 'macro') {

            const _productList = productList[index].microProducts;

            _productList.map(({price}) => {

                const grossPrice = calculateGrossPrice(price.netPrice,
                    price.tax.taxPercent).toFixed(2);
                const currentPrice = lowestUpsellPrices[index];

                if (currentPrice === null || currentPrice > grossPrice)
                    lowestUpsellPrices[index] = grossPrice;

                currency = price.currency;

            });
        }

        if (productType === 'variation') {

            product.simpleProducts.map(({price}) => {

                const grossPrice = calculateGrossPrice(price.netPrice,
                    price.tax.taxPercent).toFixed(2);
                const currentPrice = lowestUpsellPrices[index];

                if (currentPrice === null || currentPrice > grossPrice)
                    lowestUpsellPrices[index] = grossPrice;

                currency = price.currency;

            });

        }

    }

    return lowestUpsellPrices;
};

export const getProductCategoryAsString = (product) => {

    if (!!!product.simpleProductInformation ||
        !!!product.simpleProductInformation.categories)
        return '';

    const {categories} = product.simpleProductInformation;
    return categories.map(({title}) => (title)).sort().join('/');
};

export const handleRouteToMacroProduct = async (e, product, locale) => {
    e.preventDefault();

    const {category, slug} = product;
    const {title} = category;

    const categoryUrl = title.toLowerCase().replace(' ', '-');

    const url = `/${locale}/${categoryUrl}-${locale}/${slug}`;
    await Router.push(url);
};

export const getMacroProductRoute = (product, locale, category) => {

    const {slug} = product;
    const {title} = !!category ? category : product.category;

    const categoryUrl = title.toLowerCase().replace(' ', '-');

    return `/${locale}/${categoryUrl}-${locale}/${slug}`;
};

export const handleRouteToJowuaProduct = async (e, product, locale) => {
    e.preventDefault();

    const {slug} = product;

    const url = `/${locale}/jowua-${locale}/product/${slug}`;
    await Router.push(url);
};

export const getJowuaProductRoute = (product, locale) => {
    const {slug} = product;

    return `/${locale}/jowua-${locale}/product/${slug}`;
};

export const sanitizeModelString = (title, categoryString) => {

    if (!!categoryString)
        return title.replace('{{model}}', categoryString);

    return title.replace('{{model}}', '');

};

export const calculateAverageRating = (reviews) => {
    return (_.meanBy(reviews, (review) => review.stars)).toFixed(2);
};
