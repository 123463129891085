import {calculateSubTotalNetFromLineItems} from '../../lib/utils/checkoutUtils';
import {parseToPriceString} from '../../lib/utils/priceUtils';
import {useTranslation} from 'next-i18next';

export default function SmallCartOverview({cart, className}) {

  const {t: translateCommon} = useTranslation('common');
  const {t: translatePages} = useTranslation('pages');

  const {lineItems, currency, taxPercent} = cart;

  const subTotalNet = calculateSubTotalNetFromLineItems(lineItems);
  const grossNet = subTotalNet * (1 + (taxPercent / 100));

  return <div className={`pl-3 flex flex-col gap-2 pr-9 gap-2 `}
              id={'small-cart-overview'}>
    {/*<div className={'flex flex-row justify-between '}>*/}
    {/*  <span className={'text-sm text-gray-600'}>{translateOrder(*/}
    {/*      'SUBTOTAL_ARTICLES')}</span>*/}
    {/*  <span*/}
    {/*      className={'text-sm text-gray-900'}>{parseToPriceString(*/}
    {/*      subTotalNet)} </span>*/}
    {/*</div>*/}
    <div className={'flex flex-row justify-between '}>
      <span className={'text-sm text-gray-600'}>{translateCommon('SUM')}</span>
      <span
          className={'text-sm text-gray-900'}>{parseToPriceString(
          grossNet)} </span>
    </div>
    <div className={'flex flex-row justify-end'}>
      <span className={'text-tiny text-gray-600'}>{translatePages(
          'LAYOUT.CART.SHIPPING_COST_CALCULATION_ON_CHECKOUT')}</span>
    </div>
  </div>;
}