import Cookie from 'js-cookie';
import axios from 'axios';
import clientConfig from '../../../client.config';

const API_URL = clientConfig.serverBaseUrl;

export const findStaticAsync = (path, locale, defaultLocale) => {
  const localeReq = locale !== defaultLocale ? `&_locale=${locale}` : '';

  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/${path}?${localeReq}`).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });

};

export const createAsync = (data, path, authenticatedRoute = false) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.post(`${API_URL}/${path}`, data, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const countSearchAsync = (data, path, authenticatedRoute = false) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.post(`${API_URL}/${path}/count`, data, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const searchAsync = (data, path, authenticatedRoute = false) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.post(`${API_URL}/${path}/search`, {data}, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const findAsync = (
    path, authenticatedRoute = false, query = '',
    blockOnTheServer = true) => {

  //prevent function from being ran on the server
  if (typeof window === 'undefined' && blockOnTheServer) {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.get(
        `${API_URL}/${path}?${query}`,
        {
          headers,
        }).
        then(async response => {

          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const findOneAsync = (
    path, id, authenticatedRoute = false) => {

  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.get(
        `${API_URL}/${path}/${id}`,
        {
          headers,
        }).
        then(async response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const countAsync = (path, query = '') => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    const jwt = Cookie.get('token');
    if (!!!jwt) {
      reject('Can not find jwt');
    }
    axios.get(`${API_URL}/${path}/count?${query}`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).
        then(async response => {

          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};

export const updateAsync = (data, path, id, authenticatedRoute = true) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.put(`${API_URL}/${path}${!!id ? '/' + id : ''}`, data, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const updateStaticAsync = (data, path, authenticatedRoute = true) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.put(`${API_URL}/${path}`, data, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
export const deleteAsync = (path, id, authenticatedRoute = true) => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {

    let headers = {};

    if (authenticatedRoute) {
      const jwt = Cookie.get('token');
      if (!!!jwt) {
        reject('Can not find jwt');
      }

      headers = {
        'Authorization': `Bearer ${jwt}`,
      };
    }

    axios.delete(`${API_URL}/${path}/${id}`, {
      headers,
    }).
        then(response => {
          resolve(response.data);
        }).
        catch((error) => {
          reject(error);
        });
  });
};
