import _ from 'lodash';
import {parseQuestionType, QUESTION_TYPE} from '../models/enum/questionType';
import {PRODUCT_QUESTION_TYPE} from '../models/enum/productQuestionType';
import {
  calculateShippingInformation,
  calculateVariationProductTotalPrice,
  calculateVariationStocks,
  getGrossPriceString,
  initQuestions,
} from './productUtils';
import {checkIfObjectHasKeys} from './generalUtils';

/**
 *
 * Find the next question recursively based on the active flag of the question
 *
 * @param questionIndex
 * @param allQuestions
 * @param jumpIndex
 * @returns {{questionIndex: *, question: ({active}|*)}|null|*}
 */

export const findNextQuestionRec = (questionIndex, allQuestions, jumpIndex) => {

  const nextQuestionIndex = questionIndex + 1;
  const nextQuestion = allQuestions[nextQuestionIndex];
  const _jumpIndex = jumpIndex + 1;

  if (nextQuestionIndex > allQuestions.length) {
    return null;
  }

  if (nextQuestion.active) {
    return {nextQuestion, jumpIndex: _jumpIndex};

  } else {
    return findNextQuestionRec(nextQuestionIndex, allQuestions, _jumpIndex);
  }
};

/**
 *
 * Find the prev question recursively based on the active flag of the question
 *
 * @param questionIndex
 * @param allQuestions
 * @param jumpIndex
 * @returns {{questionIndex: number, question: *}|*|null}
 */

export const findPrevQuestionRec = (questionIndex, allQuestions, jumpIndex) => {

  const prevQuestionIndex = questionIndex - 1;
  const prevQuestion = allQuestions[prevQuestionIndex];
  const _jumpIndex = jumpIndex + 1;

  if (prevQuestionIndex < 0) {
    return null;
  }

  if (prevQuestion.active) {
    return {prevQuestion, jumpIndex: _jumpIndex};

  } else {
    return findPrevQuestionRec(prevQuestionIndex, allQuestions, _jumpIndex);
  }
};

/**
 *
 * @param allQuestions
 * @returns {*[]}
 */
export const initCurrentSelection = (allQuestions) => {

  const _allQuestions = [...allQuestions];

  const currentSelection = [];

  _allQuestions.map((question, index) => {

    const questionObject = {
      question: question.Question,
      shortQuestion: question.shortQuestion,
      index: index,
      answer: {
        id: null,
        text: null,
      },
      microProducts: [],
    };

    currentSelection.push(questionObject);

  });

  return currentSelection;
};

export const initAllQuestions = (macroProduct, bundleProduct) => {

  let allQuestions = [];
  const productQuestions = initQuestions(macroProduct,
      PRODUCT_QUESTION_TYPE.MAIN);

  allQuestions = allQuestions.concat(productQuestions.productQuestions);

  const hasBundledProduct = checkIfObjectHasKeys(bundleProduct);

  let bundledProductQuestions;
  if (hasBundledProduct) {
    allQuestions = allQuestions.concat(productQuestions.bundledQuestion);
    bundledProductQuestions = initQuestions(bundleProduct,
        PRODUCT_QUESTION_TYPE.BUNDLE);
    allQuestions = allQuestions.concat(
        bundledProductQuestions.productQuestions);
  }

  allQuestions = allQuestions.concat(productQuestions.installationQuestion);

  return allQuestions;
};

export const findIntersectedProductId = (microProductArrays) => {

  if (microProductArrays.length === 0) {
    return null;
  }

  if (microProductArrays.length === 1) {
    return microProductArrays;
  } else {

    const prevArr = microProductArrays.pop();
    const nextArr = microProductArrays.pop();

    let intersection = _.intersection(prevArr, nextArr);

    const newArr = [...microProductArrays, [...intersection]];

    return findIntersectedProductId(newArr);

  }

};

export const getUpdatedMicroProducts = (possibleProducts, questionId,
                                        microProducts) => {

  const possibleProduct = {
    questionId: questionId,
    products: microProducts,
  };

  const cleanList = possibleProducts.filter(
      product => !(product.questionId === possibleProduct.questionId));

  cleanList.push(possibleProduct);

  return cleanList;

};

export const getProductInformation = (productConfiguration) => {

  const {
    allAnswers,
    bundleProduct,
    mainProduct,
  } = productConfiguration;

  const {hasBundleProduct, purchaseBundle} = bundleProduct;

  const isFinalAnswerList = allAnswers.filter(answer => {

    if (!!!answer)
      return false;

    const {isFinalAnswer} = answer;
    return !!isFinalAnswer;

  });

  const hasFinalAnswer = isFinalAnswerList.length > 0;
  const finalProduct = hasFinalAnswer ?
      isFinalAnswerList[0].microProducts[0] :
      null;

  try {
    const showConfig = checkIfShowConfiguration(productConfiguration);
    const productInformation = {
      price: null,
      stockInformation: null,
      mainMicroProduct: null,
      purchaseBundle: false,
      bundleMicroProduct: null,
      shippingType: null,
      showConfig,
    };

    if (!showConfig)
      return productInformation;

    if (hasBundleProduct) {

      if (purchaseBundle) {

        productInformation.purchaseBundle = true;

        const allQuestionsAnswered = validateQuestions(
            productConfiguration);
        if (!allQuestionsAnswered) return productInformation;

        const mainMicroProductId = calculateVariationPrice(
            mainProduct, finalProduct);
        const bundleMicroProductId = calculateVariationPrice(
            bundleProduct, finalProduct);

        const mainMicroProduct = productConfiguration.mainProduct.microProducts.filter(
            product => product.id === mainMicroProductId)[0];
        const bundleMicroProduct = productConfiguration.bundleProduct.microProducts.filter(
            product => product.id === bundleMicroProductId)[0];

        productInformation.mainMicroProduct = mainMicroProduct;
        productInformation.bundleMicroProduct = bundleMicroProduct;

        const bundleTax = mainMicroProduct.price.tax;
        const bundleCurrency = mainMicroProduct.price.currency;
        const bundleIsInSale = mainMicroProduct.price.inSale ||
            bundleMicroProduct.price.inSale;
        const bundleNetPrice = mainMicroProduct.price.netPrice +
            bundleMicroProduct.price.netPrice;
        const bundleSalePrice = (mainMicroProduct.price.inSale ?
                mainMicroProduct.price.salePrice :
                mainMicroProduct.price.netPrice) +
            (bundleMicroProduct.price.inSale ?
                bundleMicroProduct.price.salePrice :
                bundleMicroProduct.price.netPrice);

        productInformation.price = {
          netPrice: bundleNetPrice,
          salePrice: bundleSalePrice,
          currency: bundleCurrency,
          inSale: bundleIsInSale,
          tax: bundleTax,
          __typename: 'ComponentComplexProductPrice',
        };

        const mainProductShipping = calculateShippingInformation(
            mainMicroProduct.stocks);
        const bundleProductShipping = calculateShippingInformation(
            bundleMicroProduct.stocks);

        productInformation.stockInformation = getFinalStockInformation(
            [mainProductShipping, bundleProductShipping]);

        productInformation.shippingType = [
          ...mainMicroProduct.shipping_types,
          ...bundleMicroProduct.shipping_types];

      } else {
        const microProductId = calculateVariationPrice(
            productConfiguration.mainProduct, finalProduct);
        const microProduct = productConfiguration.mainProduct.microProducts.filter(
            product => product.id === microProductId)[0];

        productInformation.mainMicroProduct = microProduct;

        const _stockInformation = calculateShippingInformation(
            microProduct.stocks);

        productInformation.price = microProduct.price;
        productInformation.stockInformation = _stockInformation;
        productInformation.shippingType = [
          ...microProduct.shipping_types];

      }

    } else {

      const {mainProduct} = productConfiguration;
      const {answers} = mainProduct;

      const microProductId = calculateVariationPrice(
          productConfiguration.mainProduct, finalProduct);
      const microProduct = productConfiguration.mainProduct.microProducts.filter(
          product => product.id === microProductId)[0];

      productInformation.mainMicroProduct = microProduct;

      productInformation.price = microProduct.price;

      productInformation.stockInformation = calculateShippingInformation(
          microProduct.stocks);

      productInformation.shippingType = [
        ...microProduct.shipping_types];

    }

    return productInformation;
  } catch (e) {
    console.error(e);
  }

};

export const getFinalStockInformation = (stockInfoArray) => {

  if (!Array.isArray(stockInfoArray) || stockInfoArray.length <= 0) return null;

  const sortedArray = stockInfoArray.sort((a, b) => {

    const {maxDay: aMaxDay} = a.shippingTime;
    const {maxDay: bMaxDay} = b.shippingTime;

    if (aMaxDay > bMaxDay)
      return -1;

    return 1;

  });

  return sortedArray[0];

};

/**
 *
 * Returns a micro product id
 *
 * @param product
 * @param finalProduct Final product is already detected. No need for further calculation
 * @returns {*}
 */

const calculateVariationPrice = (product, finalProduct = null) => {

  if (finalProduct)
    return finalProduct.id;

  const allMicroProducts = product.possibleProducts.map(
      item => item.products.map(product => product.id));

  return findIntersectedProductId(allMicroProducts)[0][0];

};

/**
 *
 * Return if all configuration questions has been answered (without installation)
 *
 * @param productConfiguration
 * @returns {boolean}
 */
const validateQuestions = (productConfiguration) => {

  let allQuestionsAnswered = true;

  productConfiguration.allQuestions.map((question, index) => {

    // if (question.type !== QUESTION_TYPE.INSTALLATION)
    allQuestionsAnswered = allQuestionsAnswered &&
        productConfiguration.allAnswers[index] !== null;

  });

  return allQuestionsAnswered;

};

export const addInstallationCost = (installationCost, price) => {

  if (installationCost === null) return price;

  const _updatedPrice = {
    ...price,
  };

  _updatedPrice.inSale = (installationCost.price.inSale || false) ||
      (price.inSale ||
          false);
  _updatedPrice.netPrice = price.netPrice + installationCost.price.netPrice;
  _updatedPrice.salePrice = (price.salePrice ?
          price.salePrice :
          price.netPrice) +
      (installationCost.price.salePrice ?
          installationCost.price.salePrice :
          installationCost.price.netPrice);

  const priceString = getGrossPriceString(_updatedPrice.netPrice,
      _updatedPrice.tax.taxPercent, _updatedPrice.currency, true);

  const salePriceString = getGrossPriceString(_updatedPrice.salePrice,
      _updatedPrice.tax.taxPercent, _updatedPrice.currency, true);

  _updatedPrice.priceString = priceString;
  _updatedPrice.salePriceString = salePriceString;

  return _updatedPrice;

};

export const handleBundleAnswer = (
    question, answer, setPurchaseProductAsBundle, _allQuestions,
    macroProduct, productConfiguration, setProductConfiguration) => {

  const _purchaseProductAsBundle = answer.macroProduct !== null;
  setPurchaseProductAsBundle(_purchaseProductAsBundle);

  _allQuestions.map(question => {

    const questionType = parseQuestionType(question.type);
    const isVariationQuestion = questionType === QUESTION_TYPE.COLOR ||
        questionType === QUESTION_TYPE.LABEL;

    const isBundleProductQuestion = question.id !== macroProduct.id;

    if (isVariationQuestion && isBundleProductQuestion) {

      question.active = _purchaseProductAsBundle;
    }

  });

};

export const handleInstallationAnswer = (question, answer) => {

};

export const findFirstProductQuestion = (mainProduct, bundleProduct) => {

  // productQuestions: any[], bundledQuestion: any[], installationQuestion: any[]
  const bundleProductExists = bundleProduct !== null;

  if (mainProduct.questions.productQuestions.length > 0) {
    return mainProduct.questions.productQuestions[0];

  } else if (mainProduct.questions.bundledQuestion.length > 0) {

    return mainProduct.questions.bundledQuestion[0];
  } else if (bundleProduct.hasBundleProduct &&
      bundleProduct.questions.productQuestions.length > 0) {

    return bundleProduct.questions.productQuestions.length;
  } else if (bundleProduct.hasBundleProduct &&
      bundleProduct.questions.bundledQuestion.length > 0) {
    return bundleProduct.questions.bundledQuestion.length;

  } else if (mainProduct.questions.bundledQuestion.length > 0) {
    return mainProduct.questions.bundledQuestion[0];
  } else return mainProduct.questions.installationQuestion[0];
};

export const checkIfShowConfiguration = (productConfiguration) => {

  const {allQuestions, allAnswers} = productConfiguration;

  const isAnsweredList = [];

  for (let questionIndex in allQuestions) {

    const question = allQuestions[questionIndex];
    const {active: questionIsActive} = question;

    if (questionIsActive) {
      const questionAnswered = !!allAnswers[questionIndex];
      isAnsweredList.push(questionAnswered);
    }

  }

  return isAnsweredList.every(v => v === true);

};

export const setUpVariationProductConfiguration = (
    setValue, selectedAnswerList, allSimpleProductList) => {

  let totalPrice;
  let stocks;
  let lineItemList;

  if (selectedAnswerList.length === 0) {

    totalPrice = calculateVariationProductTotalPrice(
        {discount: null, simpleProducts: allSimpleProductList});
    stocks = calculateVariationStocks({simpleProducts: allSimpleProductList});
    lineItemList = allSimpleProductList;

  } else {

    const selectedSimpleProductsIdList = selectedAnswerList.map(
        ({simpleProducts}) => simpleProducts.map(({id}) => parseInt(id)));
    lineItemList = selectedSimpleProductsIdList.reduce(
        (previous, current) => _.intersection(previous, current)).
        map(productId => {
          return allSimpleProductList.find(
              ({id}) => productId === parseInt(id));
        });
    totalPrice = calculateVariationProductTotalPrice(
        {discount: null, simpleProducts: lineItemList});
    stocks = calculateVariationStocks({simpleProducts: lineItemList});

  }

  setValue('productConfiguration.lineItems', lineItemList);
  setValue('productConfiguration.totalPrice', totalPrice);
  setValue('productConfiguration.stocks', stocks);

};
