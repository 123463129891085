/***
 *
 * @param light
 * @param props
 * @param type: Social, big, simple, logo
 * @returns {JSX.Element}
 * @constructor
 */
export default function AMPTechLogoSvg({
                                         light,
                                         props,
                                         type,
                                         style,
                                         useCustomSwooshColor,
                                         swooshColor,
                                       }) {

  const colors = {
    red: '#ce171c',
    white: '#fffefc',
    black: '#11120f',
  };

  const lightTheme = !!light;
  const _style = !!style ? style : '';

  if (type === 'big')
    return (
        <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 251.051 101.478"
            clipRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
          <defs>
            <path
                d="M-198.297-224.298h570.214v403.185h-570.214v-403.185z"
                id="prefix__a"
            />
          </defs>
          <g>
            <g>
              <clipPath id="prefix__b">
                <use xlinkHref="#prefix__a" fill="none" overflow="visible"/>
              </clipPath>
              <g clipPath="url(#prefix__b)">
                <path
                    d="M248.195 99.746V68.868h-3.183v14.135h-12.95V68.868h-3.21v30.878h3.21V85.333h12.95v14.413h3.183zm-23.766-10.16h-3.265c0 4.931-2.221 8.08-7.301 8.08-5.276 0-7.918-2.878-7.918-13.546 0-9.942 1.497-13.085 7.66-13.085 5.541 0 7.451 2.6 7.451 7.999h3.312c0-6.448-2.642-10.444-10.668-10.444-8.642 0-11.128 4.463-11.128 15.496 0 11.183 2.709 15.883 11.338 15.883 7.613 0 10.512-4.856 10.512-10.41m-25.114 10.16v-2.296h-14.21V85.299h13.255v-2.33h-13.255V71.197h14.088v-2.33h-17.244v30.879l17.366-.027zM178.596 71.17v-2.33h-20.482v2.33h8.602V99.72h3.264V71.197l8.616-.027zm-44.418 3.698a5.879 5.879 0 011.531.21c1.616.642 2.427 1.935 2.431 3.881a7.08 7.08 0 01-.115 1.233c-.562 1.822-1.889 2.71-3.969 2.71h-19.587v-8.007l19.709-.027zm-30.018-7.484v31.949a.128.128 0 00.149.149h10.011a.129.129 0 00.149-.15v-8.716h24.003a11.514 11.514 0 005.29-1.355c3.125-2.005 4.687-4.459 4.687-7.362v-5.98a8.13 8.13 0 00-1.443-4.329 10.898 10.898 0 00-8.805-4.328h-33.892c-.081 0-.129.048-.149.15m-51.394 0V99.36a.129.129 0 00.15.149h10.01a.136.136 0 00.149-.15V80.308C72.232 90.95 76.851 96.28 76.933 96.298h.149l13.762-15.99v19.025a.128.128 0 00.15.149h10.037c.081 0 .122-.048.122-.15V67.385a.143.143 0 00-.122-.149H90.695c-.04 0-4.61 5.32-13.708 15.957L63.224 67.236H52.915c-.08 0-.128.048-.149.15M1.488 99.332v.149h11.907c.217-.298 4.28-7.17 12.191-20.617.491.694.944 1.415 1.355 2.16 7.03 12.024 10.688 18.177 10.972 18.457h11.833v-.061c-.339-.624-4.577-7.837-12.713-21.64-4.028-6.855-6.141-10.37-6.34-10.546h-10.24C20 67.931 13.678 78.63 1.487 99.333"
                    fill={lightTheme ? colors.white : colors.black}
                />
                <path
                    d="M192.054 2.993h-79.543l-10.444 10.444h56.894c0 7.225-2.032 13.32-6.096 18.287-4.064 4.967-8.898 7.45-14.501 7.45h-62L65.696 49.843h93.685c8.845 0 16.503-4.515 22.974-13.546 6.47-9.03 9.703-20.125 9.699-33.283"
                    fill={!!useCustomSwooshColor ? swooshColor : colors.red}
                />
              </g>
            </g>
            <path
                d="M200.773 4.062a2.37 2.37 0 00-1.743.727 2.385 2.385 0 00-.718 1.746c0 .687.238 1.273.715 1.758a2.364 2.364 0 001.746.726c.685 0 1.267-.242 1.746-.726.479-.485.719-1.07.719-1.758 0-.682-.24-1.265-.719-1.746a2.373 2.373 0 00-1.746-.727zm2.871 2.473a2.78 2.78 0 01-.84 2.035 2.76 2.76 0 01-2.031.844 2.75 2.75 0 01-2.028-.844 2.787 2.787 0 01-.832-2.035c0-.79.28-1.464.84-2.024a2.752 2.752 0 012.02-.836c.794 0 1.471.28 2.031.836.56.558.84 1.232.84 2.024zm-2.121-.625c0-.23-.07-.383-.209-.461-.14-.078-.363-.117-.67-.117h-.473v1.14h.5c.234 0 .41-.023.527-.07.217-.086.325-.25.325-.492zm-.805-.957c.391 0 .677.038.859.113.326.135.489.401.489.797 0 .281-.103.488-.309.621-.109.07-.262.121-.457.152.247.04.428.142.543.309a.86.86 0 01.172.488v.23c0 .074.002.152.008.235a.506.506 0 00.027.164l.02.04h-.52l-.008-.032-.008-.035-.011-.102v-.25c0-.364-.099-.605-.297-.722-.117-.068-.323-.102-.617-.102h-.438v1.242h-.555V4.953h1.102z"
                fill={lightTheme ? colors.white : colors.black}
            />
          </g>
        </svg>
    );

  // if (type === 'simple')
  return (
      <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 546.846 54.005"
          clipRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
      >
        <defs>
          <path d="M-2.833-3.895h583.402v57.19H-2.833v-57.19z"
                id="prefix__a"/>
        </defs>
        <g>
          <g>
            <clipPath id="prefix__b">
              <use xlinkHref="#prefix__a" fill="none" overflow="visible"/>
            </clipPath>
            <g clipPath="url(#prefix__b)">
              <path
                  d="M516.64 48.58V2.99h-4.66v20.87h-19.15V2.99h-4.74v45.58h4.74V27.31h19.12v21.27h4.69zm-35.09-15h-4.82c0 7.28-3.28 11.92-10.78 11.92-7.8 0-11.69-4.24-11.69-19.95 0-14.68 2.21-19.33 11.31-19.33 8.18 0 11 3.85 11 11.82h4.89c0-9.52-3.9-15.43-15.75-15.43-12.76 0-16.43 6.6-16.43 22.88 0 16.51 4 23.45 16.74 23.45 11.24 0 15.52-7.16 15.52-15.36m-37.08 15V45.2h-20.97V27.25h19.57v-3.44h-19.57V6.44h20.8V2.99h-25.46v45.58l25.63.01zM413.88 6.44V2.99h-30.24v3.44h12.69v42.14h4.82V6.44h12.73zm-65.59 5.46a8.295 8.295 0 012.27.31c2.393.94 3.59 2.847 3.59 5.72a9.586 9.586 0 01-.18 1.82c-.82 2.69-2.78 4-5.85 4h-28.91V11.9h29.08zM304.01.8v47.19a.19.19 0 00.22.22h14.78c.06.006.12-.015.163-.057a.203.203 0 00.057-.163V35.1h35.41a16.89 16.89 0 007.81-2c4.62-2.96 6.93-6.583 6.93-10.87V13.4a12.068 12.068 0 00-2.13-6.39 16.1 16.1 0 00-13-6.39h-50.04c-.12 0-.19.07-.22.22m-75.88 0v47.15a.19.19 0 00.22.22h14.78c.06.006.12-.015.163-.057a.203.203 0 00.057-.163V19.88c13.54 15.713 20.357 23.583 20.45 23.61h.23l20.32-23.61v28.11a.19.19 0 00.22.22h14.82c.12 0 .18-.07.18-.22V.8a.21.21 0 00-.18-.22h-15.26c-.06 0-6.807 7.853-20.24 23.56L243.55.58h-15.22c-.12 0-.19.07-.22.22M152.4 47.99v.22h17.58c.32-.44 6.32-10.587 18-30.44a35.313 35.313 0 012 3.2c10.38 17.753 15.78 26.833 16.2 27.24h17.46v-.08c-.5-.92-6.757-11.57-18.77-31.95C198.923 6.06 195.803.867 195.51.6H180.4c-.667 1.04-10 16.837-28 47.39"
                  fill={lightTheme ? colors.white : colors.black}
              />
              <path
                  d="M133.05-.01H49.3l-11 11h59.91c0 7.613-2.15 14.027-6.45 19.24-4.3 5.213-9.387 7.823-15.26 7.83H11.23L0 49.29h98.64c9.333 0 17.397-4.75 24.19-14.25 6.793-9.5 10.2-21.183 10.22-35.05"
                  fill={!!useCustomSwooshColor ? swooshColor : colors.red}
              />
            </g>
          </g>
          <path
              d="M527.011 2.568a2.37 2.37 0 00-1.742.727 2.386 2.386 0 00-.719 1.746c0 .688.239 1.274.715 1.758a2.365 2.365 0 001.746.726c.685 0 1.267-.242 1.747-.726.479-.484.718-1.07.718-1.758 0-.682-.239-1.264-.718-1.746a2.374 2.374 0 00-1.747-.727zm2.872 2.473a2.78 2.78 0 01-.84 2.035 2.761 2.761 0 01-2.032.844c-.794 0-1.47-.281-2.027-.844a2.784 2.784 0 01-.832-2.035c0-.789.28-1.463.84-2.023a2.75 2.75 0 012.019-.836c.795 0 1.472.278 2.032.836.56.557.84 1.231.84 2.023zm-2.122-.625c0-.23-.069-.383-.209-.46-.139-.079-.362-.118-.669-.118h-.473v1.14h.5c.234 0 .41-.023.527-.07.216-.086.324-.25.324-.492zm-.804-.957c.39 0 .677.038.859.113.326.136.488.401.488.797 0 .281-.102.489-.308.621-.11.07-.262.121-.457.153.247.039.428.142.543.308.114.167.172.33.172.489v.23c0 .073.002.151.007.234a.527.527 0 00.028.164l.019.04h-.519l-.008-.032-.008-.035-.012-.101v-.25c0-.365-.099-.606-.296-.723-.118-.068-.323-.102-.618-.102h-.437v1.242h-.555V3.46h1.102z"
              fill={lightTheme ? colors.white : colors.black}
          />
        </g>
      </svg>
  );

}