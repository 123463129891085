export const toggleSideBar = (e, ui, setUi) => {
  let _ui = {
    ...ui,
  };

  const {cartIsOpen, sideMenuIsOpen} = _ui;

  _ui.sideMenuIsOpen = !sideMenuIsOpen;

  if (cartIsOpen)
    _ui.cartIsOpen = false;

  setUi(_ui);

};

export const toggleCart = (e, ui, setUi) => {

  let _ui = {
    ...ui,
  };

  const {cartIsOpen, sideMenuIsOpen} = _ui;

  _ui.cartIsOpen = !cartIsOpen;

  if (sideMenuIsOpen)
    _ui.sideMenuIsOpen = false;

  setUi(_ui);

};

export const closeSideBar = (ui, setUi) => {

  let _ui = {
    ...ui,
  };

  _ui.sideMenuIsOpen = false;

  setUi(_ui);

};

export const closeAll = (e, ui, setUi) => {

  e.preventDefault();

  let _ui = {
    ...ui,
  };

  _ui.sideMenuIsOpen = false;
  _ui.cartIsOpen = false;

  setUi(_ui);

};