import {useState} from 'react';

export function useLocalStorage(key, initialValue) {

  const [storedValue, setStoredValue] = useState(() => {

    if (typeof window !== 'undefined' &&
        !!navigator.cookieEnabled &&
        !!window.localStorage) {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        return initialValue;
      }
    } else {
      return initialValue;
    }
  });
  const setValue = (value) => {

    if (typeof window !== 'undefined' &&
        !!navigator.cookieEnabled && !!window.localStorage) {
      try {
        const valueToStore =
            value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
      }
    }
  };

  return [storedValue, setValue];
}