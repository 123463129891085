import Lottie from 'lottie-react';
import loadingPaperPlane from '../../public/lottie/loading-paperplane.json';
import edupiaLoading from '../../public/lottie/edupia-loading.json';
import classNames from "classnames";

export const LoadingCircle = ({
                                  animationIsStopped,
                                  className = '',
                                  height = 200,
                                  width = 200,
                                  speed = 1,
                              }) => {

    return <center>
        <Lottie
            animationData={edupiaLoading}
            className={classNames(className)}
            style={{height, width}}
            loop={true}
            autoplay={true}
            rendererSettings={
                {preserveAspectRatio: 'xMidYMid slice'}
            }
        /></center>;

};
