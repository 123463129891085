import {Fragment, useEffect, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';

const TailwindUiModal = ({
                           reference = null,
                           children,
                           setOpen,
                           open = false,
                           showCloseButton = true,
                           onClose = () => setOpen(false),
                           isLoading = true,
                           showPadding = true,
    maxWidth = 'max-w-lg'
                         }) => {

  return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-40" onClose={onClose}
            initialFocus={reference}>
      <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
      >
        <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </Transition.Child>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div
            className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
                className={`relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:${maxWidth} sm:w-full ${showPadding &&
                'px-4 pt-5 pb-4 sm:p-6'}`}>
              {showCloseButton && <svg onClick={(e) => setOpen(false)}
                                       className="w-6 h-6 ml-auto text-gray-700 hover:text-gray-500 cursor-pointer transition-colors"
                                       fill="none"
                                       stroke="currentColor"
                                       viewBox="0 0 24 24"
                                       xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
              </svg>}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>;
};

export default TailwindUiModal;
