import Image from 'next/image';
import React from 'react';

const Flag = ({locale, className}) => {

  let _locale = locale;

  if (locale === 'en')
    _locale = 'gb';

  const src = `/flags/1x1/${_locale.toLowerCase()}.svg`;
  const alt = `${_locale}_flag`;

  return <div
      className={className}>
    <Image
        className={className}
        src={src}
        alt={alt}
        height={50}
        width={50}
        layout={'responsive'}
        priority
    />
  </div>;
  ;

};

export default Flag;
