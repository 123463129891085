import React, {useContext, useEffect} from 'react';

import UserMenu from '../dashboard/partials/header/UserMenu';
import {
  UIContext,
  UserContext,
} from '../../utils/context/appContext';
import {useRouter} from 'next/router';

export default function DashboardHeader({
                                          sidebarOpen,
                                          setSidebarOpen,
                                        }) {

  const [user, setUser] = useContext(UserContext);
  const [ui, setUi] = useContext(UIContext);
  const {asPath} = useRouter();
  const {isAuthenticated, data} = user;

  const isWarehousePage = asPath.includes('/dashboard/stock');

  return (
      <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">

            {/* Header: Left side */}
            <div className="flex">

              {isWarehousePage && <div>
                <WarehouseLegends/>

              </div>}

              {/* Hamburger button */}
              {/*<button*/}
              {/*    className="text-gray-500 hover:text-gray-600 lg:hidden"*/}
              {/*    aria-controls="sidebar"*/}
              {/*    aria-expanded={sidebarOpen}*/}
              {/*    onClick={(e) => toggleSideBar(e, ui, setUi)}*/}
              {/*>*/}
              {/*  <span className="sr-only">Open sidebar</span>*/}
              {/*  <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24"*/}
              {/*       xmlns="http://www.w3.org/2000/svg">*/}
              {/*    <rect x="4" y="5" width="16" height="2"/>*/}
              {/*    <rect x="4" y="11" width="16" height="2"/>*/}
              {/*    <rect x="4" y="17" width="16" height="2"/>*/}
              {/*  </svg>*/}
              {/*</button>*/}

            </div>

            {/* Header: Right side */}
            <div className="flex items-center w-56">

              {/*<SearchModal />*/}
              {/*<Notifications />*/}
              {/*<Help />*/}
              {/*  Divider */}
              <hr className="w-px h-6 bg-gray-200 mx-3"/>

              {data !== null ? <UserMenu user={user}/> : <></>}


            </div>

          </div>
        </div>
      </header>
  );
}

const WarehouseLegends = () => {

  return <ul
      className={'flex flex-row gap-5 text-tiny bg-white p-2 rounded h-9'}>
    <li>S (In Stock)</li>
    <li>B (Backorder)</li>
    <li>P (Primary Location)</li>
    <li>R (Return Location)</li>
  </ul>;

};