import {useEffect, useRef, useState} from 'react';
import {useSpring, animated} from 'react-spring';
import {Loader} from '../animations/loader';

export default function CoreButton({
                                     // ref,
                                     onClick,
                                     disabled,
                                     isLoading,
                                     children,
                                     className,
                                     loadingColor = 'white',
                                   }) {

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  // Save the dimensions here
  useEffect(
      () => {
        if (ref.current && ref.current.getBoundingClientRect().width) {
          const _width = ref.current.getBoundingClientRect().width;
          setWidth(_width <= 130 ? 140 : _width + 15);
        }
        if (ref.current && ref.current.getBoundingClientRect().height) {
          setHeight(ref.current.getBoundingClientRect().height);
        }
      },
      // children are a dep so dimensions are updated if initial contents change
      [],
  );

  const fadeOutProps = useSpring({opacity: isLoading ? 1 : 0});
  const fadeInProps = useSpring({opacity: isLoading ? 0 : 1});

  return (
      <button
          ref={!!ref ? ref : null}
          onClick={e => {
            !!onClick && !isLoading ? onClick(e) : null;
          }}
          disabled={!!isLoading ? !!isLoading : !!disabled}
          className={className}
          style={
            width && height
                ? {
                  width: `${width}px`,
                  height: `${height}px`,
                }
                : {}
          }
      >
        {!!isLoading ?
            <animated.div style={fadeOutProps}>
              <Loader size={'6'} color={loadingColor}/>
            </animated.div>
            :
            isLoading ?
                <animated.div style={fadeInProps}>{children}</animated.div> :
                <animated.div style={fadeInProps}>{children}</animated.div>

        }
      </button>
  );

}
