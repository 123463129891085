import React, {useState, useRef, useEffect, useContext} from 'react';
import Transition from '../../../../lib/utils/dashboardUtils/Transition';
import {logout} from '../../../../lib/api/rest/auth';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';
import {UserContext} from '../../../../utils/context/appContext';

function UserMenu({user, lightTheme}) {

  const {t: translateMenu} = useTranslation('menu');
  const [, setUser] = useContext(UserContext);

  const {isAuthenticated, data} = user;
  const {foreName, lastName, partnerWorkshop, role,ampPartner} = data;
  const {name: roleName} = role;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({target}) => {
      if (!dropdownOpen || dropdown.current.contains(target) ||
          trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
      <div className="relative inline-flex w-full">
        <button
            ref={trigger}
            className="inline-flex justify-end group w-full"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
        >
          <div className="flex items-center truncate">
            <span
                className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">{foreName} {lastName}</span>
            <svg
                className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
                viewBox="0 0 12 12">
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"/>
            </svg>
          </div>
        </button>

        <Transition
            className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 px-2"
            show={dropdownOpen}
            enter="transition ease-out duration-200 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-200"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
        >
          <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
          >
            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
              <div
                  className="font-medium text-gray-800">{foreName} {lastName}</div>
              <div
                  className="text-xs text-gray-800"> {roleName} </div>
              {!!ampPartner ?
                  <div
                      className="text-xs text-gray-500 italic"> {ampPartner.title} </div> :
                  <></>}
            </div>
            <ul>
              <li>

                <Link href={'/dashboard/settings'}>
                  <a onClick={() => setDropdownOpen(!dropdownOpen)}
                     className={`font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3`}>
                    {translateMenu('SETTINGS')}
                  </a>
                </Link>
              </li>
              <li>
                <button
                    className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                    onClick={async () => await logout('/login',
                        setUser)}
                >
                  {translateMenu('LOGOUT')}
                </button>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
  );
}

export default UserMenu;